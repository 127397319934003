import axios from 'axios';
import store, { RootState } from '../store';
import authService from './authService';

// Create an instance of axios with base URL and default settings
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URI, // Replace with your actual base URL
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 20000, // Set a timeout for requests (optional)
});

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      console.warn("401 Unauthorized: Attempting token refresh...");
      const newToken = await authService.refreshToken();
      if (newToken) {
        error.config.headers.Authorization = `Bearer ${newToken}`;
        return apiClient.request(error.config); // Retry original request
      }
    }
    return Promise.reject(error);
  }
);

// Add a request interceptor
apiClient.interceptors.request.use(
  (config) => {
    const token = authService.getToken();
    if (config?.headers && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);


// HTTP Service with generic support for GET, POST, PUT, DELETE
export const httpService = {
  // GET request with a generic response type
  get: async <T>(url: string, params?: Record<string, unknown>): Promise<T> => {
    const response = await apiClient.get<T>(url, { params });
    return response.data; // Returns data of type T
  },

  // POST request with a generic response type
  post: async <T>(
    url: string,
    data?: Record<string, unknown> | FormData,
    config?: { headers?: Record<string, string> }
  ): Promise<T> => {
    const headers: Record<string, string> = {
      ...(config?.headers || {}), // Safely spread existing headers if provided
    };
  
    // If the data is FormData, set Content-Type to multipart/form-data
    if (data instanceof FormData) {
      headers['Content-Type'] = 'multipart/form-data';
    }
  
    const response = await apiClient.post<T>(url, data, { headers });
    return response.data; // Returns data of type T
  },
  

  // PUT request with a generic response type
  put: async <T>(url: string, data?: Record<string, unknown>): Promise<T> => {
    const response = await apiClient.put<T>(url, data);
    return response.data; // Returns data of type T
  },

  // PATCH request with a generic response type
  patch: async <T>(url: string, data?: Record<string, unknown>): Promise<T> => {
    const response = await apiClient.patch<T>(url, data);
    return response.data; // Returns data of type T
  },

  // DELETE request with a generic response type
  delete: async <T>(url: string, params?: Record<string, unknown>): Promise<T> => {
    const response = await apiClient.delete<T>(url, { params });
    return response.data; // Returns data of type T
  },
};

export default httpService;
