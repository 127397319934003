export const Constants = {
  Auth: {
    AUTH_TOKEN_KEY: 'sb-crkconcmetkpnjffehvt-auth-token',
  },
  Storage: {
    IS_PREVIEW_COMPLETED: 'IS_PREVIEW_COMPLETED'
  },
  Messages: {
    Free_Preview_Ended: 'Your Free Preview Has Ended!'
  },
  SocketChannels: {
    Test_Updates: 'test_updates'
  }
};