import React from 'react';
import { Box, Typography, Button, Divider, CircularProgress } from '@mui/material';
import { AccessTime, CheckCircleOutline, Devices, LockOutlined } from '@mui/icons-material';

interface InstructionsScreenProps {
  onNext: () => void;
  onBack: () => void;
  isResumeTest: boolean;
  loading: boolean; // For showing a spinner
  error?: string | null; // For showing error messages
}

const InstructionsScreen: React.FC<InstructionsScreenProps> = ({ onNext, onBack, isResumeTest, loading, error }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100vh',
        backgroundColor: '#F9F9F9',
      }}
    >
      {/* Main Content */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            width: '450px',
            backgroundColor: '#FFFFFF',
            padding: '2rem',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            textAlign: 'left',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: '600',
              textAlign: 'center',
              marginBottom: '1.5rem',
              color: '#212121',
            }}
          >
            {isResumeTest ? 'Resume Practice Test?' : 'Practice Test'}
          </Typography>

          {/* Instructions */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <AccessTime fontSize="medium" sx={{ color: '#757575' }} />
              <Typography sx={{ fontSize: '14px', color: '#424242' }}>
                <strong>Timing</strong>: Practice tests are timed, but you can pause them. Incomplete practice tests are deleted after 90 days.
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <CheckCircleOutline fontSize="medium" sx={{ color: '#757575' }} />
              <Typography sx={{ fontSize: '14px', color: '#424242' }}>
                <strong>Scores</strong>: Check scores in <strong>My Practice</strong>.
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <Devices fontSize="medium" sx={{ color: '#757575' }} />
              <Typography sx={{ fontSize: '14px', color: '#424242' }}>
                <strong>Assistive Technology</strong>: Configure assistive technology before the test.
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <LockOutlined fontSize="medium" sx={{ color: '#757575' }} />
              <Typography sx={{ fontSize: '14px', color: '#424242' }}>
                <strong>No Device Lock</strong>: Devices remain unlocked during practice.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Footer */}
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '1rem 2rem',
          backgroundColor: '#FFFFFF',
        }}
      >
        {error && (
          <Typography
            sx={{
              color: 'red',
              fontSize: '14px',
              marginRight: '1rem',
            }}
          >
            {error}
          </Typography>
        )}

        <Button
          variant="outlined"
          sx={{
            borderColor: '#1976D2',
            color: '#1976D2',
            fontWeight: 'bold',
            textTransform: 'none',
            marginRight: '1rem',
            '&:hover': {
              backgroundColor: '#E3F2FD',
              borderColor: '#1976D2',
            },
          }}
          onClick={onBack}
        >
          Back
        </Button>

        <Button
          variant="contained"
          sx={{
            backgroundColor: '#1976D2',
            color: '#FFFFFF',
            fontWeight: 'bold',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#1565C0',
            },
          }}
          onClick={onNext}
          disabled={loading} // Disable the button while loading
        >
          {loading ? <CircularProgress size={24} sx={{ color: '#FFF' }} /> : 'Next'}
        </Button>
      </Box>
    </Box>
  );
};

export default InstructionsScreen;
