import React from "react";
import "./DirectionsPopup.css";

interface DirectionsPopupProps {
  isVisible: boolean;
  onClose: () => void;
  moduleType: "english" | "math"; // Module type to select the content
}

const DirectionsPopup: React.FC<DirectionsPopupProps> = ({ isVisible, onClose, moduleType }) => {
  if (!isVisible) return null;

  // Define content for each module
  const content = {
    english: `
      The questions in this section address a number of important reading and writing skills.
      Each question includes one or more passages, which may include a table or graph. Read each
      passage and question carefully, and then choose the best answer to the question based on the
      passage(s).<br/><br/>
      All questions in this section are multiple-choice with four answer choices. Each question has
      a single best answer.
    `,
    math: `
      The questions in this section address a number of important math skills.<br/><br/>
      Use of a calculator is permitted for all questions. A reference sheet, calculator, and these
      directions can be accessed throughout the test.<br/><br/>
      <b>Unless otherwise indicated:</b><br/>
      - All variables and expressions represent real numbers.<br/>
      - Figures provided are drawn to scale.<br/>
      - All figures lie in a plane.<br/>
      - The domain of a given function f is the set of all real numbers x for which f(x) is a real number.<br/><br/>
      <b>For multiple-choice questions:</b> Solve each problem and choose the correct answer from the
      choices provided. Each multiple-choice question has a single correct answer.<br/><br/>
      <b>For student-produced response questions:</b> Solve each problem and enter your answer as described
      below.<br/>
      - If you find more than one correct answer, enter only one answer.<br/>
      - You can enter up to 5 characters for a positive answer and up to 6 characters (including the
      negative sign) for a negative answer.<br/>
      - If your answer is a fraction that doesn’t fit in the provided space, enter the decimal equivalent.<br/>
      - If your answer is a decimal that doesn’t fit in the provided space, enter it by truncating or
      rounding at the fourth digit.<br/>
      - If your answer is a mixed number (such as 3 1/2), enter it as an improper fraction (7/2) or its
      decimal equivalent (3.5).<br/>
      - Don't enter <b>symbols</b> such as percent sign, comma, or dollar sign
    `,
  };

  return (
    <div className="directions-popup" style={{width: "900px"}}>
      <div className="directions-popup-content">
        <p dangerouslySetInnerHTML={{ __html: content[moduleType] }} />
      </div>
      {moduleType === "math" && (
        <div className="image-container">
          <img width={"50%"} src="https://varmt.blob.core.windows.net/sat/Screenshot 2025-01-20 at 12.27.38 AM.png" alt="Math Directions" />
        </div>
      )}
      <button className="close-button" onClick={onClose}>
        Close
      </button>
    </div>
  );
};

export default DirectionsPopup;
