import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import TimerIcon from "@mui/icons-material/Timer";
import CalculateIcon from "@mui/icons-material/Calculate";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";

const RealTestExperience: React.FC = () => {
  const features = [
    {
      icon: <TimerIcon style={{ fontSize: 36, color: "#4CAF50" }} />,
      text: "Timed sections to match SAT pacing",
    },
    {
      icon: <CalculateIcon style={{ fontSize: 36, color: "#FF9800" }} />,
      text: "On-screen calculators for math sections",
    },
    {
      icon: <ImportContactsIcon style={{ fontSize: 36, color: "#2196F3" }} />,
      text: "Simulated reading passages and questions",
    },
    {
      icon: <CheckCircleIcon style={{ fontSize: 36, color: "#8E44AD" }} />,
      text: "Full-length practice exams",
    },
    {
      icon: <DesktopWindowsIcon style={{ fontSize: 36, color: "#F44336" }} />,
      text: "Same user interface as the actual test",
    },
    {
      icon: <CheckCircleIcon style={{ fontSize: 36, color: "#4CAF50" }} />,
      text: "Confidence-boosting original test environment",
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        py: 6,
        px: 4,
        backgroundColor: "#FFFFFF", // Matches app's general style
        borderTop: "1px solid #E0E0E0", // Subtle divider for section transition
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          color: "#1A1A1A",
          mb: 4,
        }}
      >
        Real SAT Exam Experience
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {features.map((feature, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {feature.icon}
              <Typography
                variant="body1"
                sx={{
                  ml: 2,
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#333",
                  textAlign: "left",
                }}
              >
                {feature.text}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RealTestExperience;
