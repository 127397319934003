import { useCallback, useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {
  Navigate,
  useLocation
} from "react-router-dom";
import { httpService } from "../../services/httpService";
import { StripeSessionResponse } from "../../interfaces/responses/StripeSessionResponse";
import { StripeCheckoutSession } from "../../interfaces/responses/StripeCheckoutSession";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test secret API key.
const stripe_publishable_key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
const stripePromise = loadStripe(stripe_publishable_key);

export const StripeCheckoutForm = () => {
  const location = useLocation();
  const item = location.state?.items[0];

  const fetchClientSecret = useCallback(async () => {
    const data = await httpService.post<StripeCheckoutSession>("/stripe/create-checkout-session", {
      priceId: item.price_id,
      selectedTest: item.selectedTest,
    });
    return data.clientSecret;
  }, [item.price_id, item.selectedTest]);

  const options = {fetchClientSecret};

  return (
    <div style={{backgroundColor: '#FFFFFF'}} id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}