import React, { useState } from "react";
import { Box, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextareaAutosize, Typography } from "@mui/material";
import PaletteIcon from "@mui/icons-material/Palette";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { useDispatch, useSelector } from "react-redux";
import { updateQuestionState } from "../../store/slices/testSlice";

interface HighlightFeatureProps {
  children: React.ReactNode;
  field: "stimulus" | "stem"; // Identify the field to update
}

const HighlightFeature: React.FC<HighlightFeatureProps> = ({ children, field }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [selectedText, setSelectedText] = useState<string>("");

  const dispatch = useDispatch();
  const moduleName = useSelector((state: any) => state.tests.activeTest.currentModuleName);
  const questionId = useSelector((state: any) => state.tests.activeTest.currentQuestionId);

  const handleMouseUp = (event: React.MouseEvent<HTMLElement>) => {
    const selection = window.getSelection();
    if (selection && selection.toString().trim() !== "") {
      setSelectedText(selection.toString());
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const applyStyleToSelection = (style: Partial<CSSStyleDeclaration>) => {
    const selection = window.getSelection();

    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);

      if (!range.collapsed) {
        // Remove Highlight: unwrap the <span> and keep its text
        if (style.backgroundColor === "transparent") {
          const parentElement = range.commonAncestorContainer.parentElement;
          if (parentElement?.tagName === "SPAN") {
            const textNode = document.createTextNode(parentElement.textContent || "");
            parentElement.replaceWith(textNode);
          }
          saveFieldChanges();
          selection.removeAllRanges(); // Safely clear the selection
          handleClose();
          return;
        }

        
        const span = document.createElement("span");
        Object.assign(span.style, style);
        span.textContent = selection.toString();

        range.deleteContents();
        range.insertNode(span);
        saveFieldChanges(); // Save updated field
      }
    }

    selection?.removeAllRanges(); // Safely handle null selection
    handleClose();
  };

  const saveFieldChanges = () => {
    const updatedContent = (document.querySelector(`.highlight-feature-${field}`) as HTMLElement)?.innerHTML;

    if (updatedContent) {
      dispatch(
        updateQuestionState({
          moduleName,
          questionId,
          updates: { [field]: updatedContent }, // Save the updated field in the question
        })
      );
    }
  };

  const addNote = () => {
    if (!selectedText) return;
  
    // Dispatch note to Redux store
    dispatch(
      updateQuestionState({
        moduleName,
        questionId,
        updates: {
          note: [
            {
              id: Date.now(),
              title: selectedText, // Use the selected text as the title
              content: "", // Initially empty
              color: "#FFEB3B", // Default highlight color
            },
          ],
        },
      })
    );
  
    // Highlight the selected text
    applyStyleToSelection({ backgroundColor: "#FFEB3B" });
    setNoteDialogOpen(false); // Close the dialog
  };

  const saveNote = (noteContent: string) => {
    // Dispatch an action to add the note
    dispatch(
      updateQuestionState({
        moduleName,
        questionId,
        updates: {
          notes: [
            ...(questionId.notes || []),
            { text: selectedText, note: noteContent }, // Add the note with the selected text
          ],
        },
      })
    );

    // Highlight the selected text
    applyStyleToSelection({ backgroundColor: "#FFEB3B" }); // Example: Yellow highlight
    setNoteDialogOpen(false); // Close the dialog
  };

  return (
    <Box
      onMouseUp={handleMouseUp}
      sx={{ position: "relative", cursor: "text" }}
      className={`highlight-feature-${field}`} // Dynamic class for querying updated field
    >
      {children}

      {/* Toolbar */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ "& .MuiMenu-paper": { padding: "8px", minWidth: "150px" } }}
      >
        <MenuItem onClick={() => applyStyleToSelection({ backgroundColor: "#FFEB3B" })}>
          <PaletteIcon sx={{ color: "#FFEB3B", mr: 1 }} />
          Yellow Highlight
        </MenuItem>
        <MenuItem onClick={() => applyStyleToSelection({ backgroundColor: "#BBDEFB" })}>
          <PaletteIcon sx={{ color: "#BBDEFB", mr: 1 }} />
          Blue Highlight
        </MenuItem>
        <MenuItem onClick={() => applyStyleToSelection({ backgroundColor: "#F8BBD0" })}>
          <PaletteIcon sx={{ color: "#F8BBD0", mr: 1 }} />
          Pink Highlight
        </MenuItem>
        <MenuItem onClick={() => applyStyleToSelection({ textDecoration: "underline" })}>
          <BorderColorIcon sx={{ mr: 1 }} />
          Underline
        </MenuItem>
        <MenuItem onClick={addNote}>
          <NoteAddIcon sx={{ color: "#0D47A1", mr: 1 }} />
          Add Note
        </MenuItem>
        <MenuItem onClick={() => applyStyleToSelection({ backgroundColor: "transparent" })}>
          <DeleteIcon sx={{ color: "red", mr: 1 }} />
          Remove
        </MenuItem>
      </Menu>

      {/* Add Note Dialog */}
      <Dialog
        open={noteDialogOpen}
        onClose={() => setNoteDialogOpen(false)}
      >
        <DialogTitle>Add Note</DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 1 }}>
            Selected Text: <strong>{selectedText}</strong>
          </Typography>
          <TextareaAutosize
            minRows={3}
            placeholder="Enter your note here..."
            style={{
              width: "100%",
              padding: "8px",
              fontSize: "14px",
              border: "1px solid #C2CFE0",
              borderRadius: "4px",
            }}
            id="noteContent"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setNoteDialogOpen(false)}
            variant="outlined"
            sx={{
              textTransform: "none",
              color: "#0D47A1",
              borderColor: "#0D47A1",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              const noteContent = (document.getElementById("noteContent") as HTMLTextAreaElement)?.value;
              saveNote(noteContent || "");
            }}
            variant="contained"
            sx={{
              textTransform: "none",
              backgroundColor: "#0D47A1",
              color: "#FFFFFF",
              "&:hover": { backgroundColor: "#1565C0" },
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default HighlightFeature;
