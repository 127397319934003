import React from "react";
import { Typography } from "@mui/material";

interface TimerProps {
  timeLeft: number;
}

const Timer: React.FC<TimerProps> = ({ timeLeft }) => {
  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <Typography variant="inherit" color="error">
      {formatTime(timeLeft)}
    </Typography>
  );
};

export default Timer;
