import { createAsyncThunk } from "@reduxjs/toolkit";
import { testService } from "../../services/testService";
import { Test } from "../../interfaces/Tests/Test";

// Async thunk to fetch tests from the API
export const fetchTests = createAsyncThunk<Test[]>('tests/fetchTests', async () => {
  const response = await testService.getTests();
  return response; // Now returns Test[]
});

// Async thunk to start a test
export const startTest = createAsyncThunk<Test, { isPreview: boolean, testId?: string }>('tests/startTest', async ({isPreview, testId}) => {
  const response = await testService.startTest(isPreview, testId);
  return response; // Now returns a Test
});

// Async thunk to start a test
export const getTest = createAsyncThunk<Test, string>('tests/user-test', async (testId) => {
  const response = await testService.getTest(testId);
  return response; // Now returns a Test
});

// Async thunk to get the next module
export const getNextModule = createAsyncThunk<Test, { testId: string; currentModuleName: string }>(
  'tests/getNextModule',
  async ({ testId, currentModuleName }) => {
    const response = await testService.getNextModule(testId, currentModuleName);
    return response; // Return the updated Test object
  }
);