import React, { useState } from "react";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import Sidebar from "./Sidebar";
import Header from "./Header";

interface LayoutProps {
  children: React.ReactNode;
  hideLayout?: boolean; // New prop to hide layout components
}

const drawerWidth = 0;
const collapsedWidth = 0;

const Layout: React.FC<LayoutProps> = ({ children, hideLayout = false }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {/* Sidebar - Only visible if hideLayout is false */}
      {/* {!hideLayout && (
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      )} */}

      <Box
        component="div"
        sx={{
          flexGrow: 1,
          marginLeft: !hideLayout
            ? isSidebarOpen
              ? `${drawerWidth}px`
              : `${collapsedWidth}px`
            : 0, // No margin when layout is hidden
          transition: "margin-left 0.3s ease-in-out",
        }}
      >
        {/* Header - Only visible if hideLayout is false */}
        {!hideLayout && <Header />}

        {/* Main Content */}
        <Box component="main">
          {!hideLayout && <Toolbar />} {/* Keeps spacing if header exists */}
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
