import { createSlice } from '@reduxjs/toolkit';
import { Test } from '../../interfaces/Tests/Test';
import { fetchTests, getNextModule, startTest } from '../thunks/testThunks';
import { RootState } from '../../store';
import messageService from '../../services/messageService';
import { socketEvents } from '../../constants/socketEvents';

type TestState = {
  tests: Test[]; // Array to hold tests
  activeTest: Test | null; // Active test details
  loading: boolean; // Loading state for API calls
  error: string | null; // Error message
  currentQuestionIndex: number; // Track the current question index
};

const initialState: TestState = {
  tests: [],
  activeTest: null,
  loading: false,
  error: null,
  currentQuestionIndex: 0, // Initialize to the first question
};

const testSlice = createSlice({
  name: 'tests',
  initialState,
  reducers: {
    resetActiveTest(state) {
      state.activeTest = null;
      state.currentQuestionIndex = 0; // Reset index
    },
    updateActiveTest(state, action) {
      if (state.activeTest) {
        const { field, value } = action.payload;
        state.activeTest = { ...state.activeTest, [field]: value };
      }
    },
    updateQuestionState(state, action) {
      const { moduleName, questionId, updates } = action.payload;
    
      if (state.activeTest && state.activeTest.modules) {
        const module = state.activeTest.modules.find((mod) => mod.moduleName === moduleName);
    
        if (module) {
          const question = module.questions.find((q) => q.questionId === questionId);
          var { note, notes, ...otherUpdates } = updates;
          if (question) {
            if (notes) {
              // Add or update notes
              question.notes = [...notes];
            }

            if (note) {
              // Add or update notes
              question.notes = [...(question.notes || []), ...note];
            }
    
            // Apply any remaining updates directly to the question
            Object.assign(question, otherUpdates);
          }

          var newUpdates = {
            ...updates
          };

          if (newUpdates.note) {
            delete newUpdates.note;
            newUpdates['notes'] = question?.notes;
          }

          // Emit updates
          messageService.sendMessage({
            testId: state.activeTest.testId,
            eventType: socketEvents.QuestionStateUpdate,
            data: { moduleName, questionId, questionUpdates: newUpdates },
          });
        }
      }
    },
    setCurrentQuestionIndex(state, action) {
      state.currentQuestionIndex = action.payload; // Set the current question index
    },
    incrementCurrentQuestionIndex(state) {
      if (
        state.activeTest &&
        state.activeTest.modules &&
        state.activeTest.modules[0]?.questions &&
        state.currentQuestionIndex < state.activeTest.modules[0].questions.length - 1
      ) {
        state.currentQuestionIndex += 1;
        state.activeTest.currentQuestionId = state.activeTest.modules[0].questions[state.currentQuestionIndex].questionId;
      }
    },
    decrementCurrentQuestionIndex(state) {
      if (state.currentQuestionIndex > 0) {
        state.currentQuestionIndex -= 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTests.fulfilled, (state, action) => {
        state.loading = false;
        state.tests = action.payload; // Now correctly typed as Test[]
      })
      .addCase(fetchTests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch tests';
      })
      .addCase(startTest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(startTest.fulfilled, (state, action) => {
        state.loading = false;
        state.activeTest = action.payload; // Now correctly typed as Test
        state.currentQuestionIndex = 0; // Reset index when a new test starts
      })
      .addCase(startTest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to start test';
      })
      .addCase(getNextModule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNextModule.fulfilled, (state, action) => {
        state.loading = false;
        state.activeTest = action.payload; // Update the activeTest with the new module data
        state.currentQuestionIndex = 0; // Reset to the first question of the new module
      })
      .addCase(getNextModule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to load the next module';
      });
  },
});

// Selector to get the currentModuleName
export const selectCurrentModuleName = (state: RootState) =>
  state.tests.activeTest?.currentModuleName || null;

// Selector to get the current section and module based on currentModuleName
export const selectCurrentSectionModule = (state: RootState): string => {
  const currentModuleName = state.tests.activeTest?.currentModuleName;

  switch (currentModuleName) {
    case 'Module 1':
      return 'Section 1, Module 1: Reading and Writing Questions';
    case 'Module 2':
      return 'Section 1, Module 2: Reading and Writing Questions';
    case 'Math Module 1':
      return 'Section 2, Module 1: Math';
    case 'Math Module 2':
      return 'Section 2, Module 2: Math';
    default:
      return 'Unknown Section and Module'; // Fallback for unexpected values
  }
};

export const selectIsEnglishModule = (state: RootState): boolean => 
  state.tests.activeTest?.currentModuleName === "Module 1" || state.tests.activeTest?.currentModuleName === "Module 2";

export const {
  resetActiveTest,
  updateActiveTest,
  updateQuestionState,
  setCurrentQuestionIndex,
  incrementCurrentQuestionIndex,
  decrementCurrentQuestionIndex,
} = testSlice.actions;

export default testSlice.reducer;
