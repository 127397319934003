import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import { toast } from 'react-toastify';
import { setSession } from '../../store/slices/authSlice';
import { supabase } from '../../clients/supabaseClient';
import httpService from '../../services/httpService';
import ToastService from '../../services/toastService';

const AuthRedirect: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const url = window.location.href;
    const [, hash] = url.split('#'); // Split the URL at the hash (#)

    if (hash) {
      const searchParams = new URLSearchParams(hash);

      const access_token = searchParams.get('access_token');
      const refresh_token = searchParams.get('refresh_token');
      const error = searchParams.get('error');

      if (error) {
        // Handle error case
        console.error('Unable to login successfully:', error);
        ToastService.error(`Unable to login successfully: ${error}`);
        // Delay navigation to show toast
        setTimeout(() => navigate('/auth'), 500);
        return;
      }

      if (access_token && refresh_token) {
        // Handle success case
        (async () => {
          try {
            const sessionResponse = await supabase.auth.setSession({
              access_token,
              refresh_token,
            });



            await httpService.post('users', {
              userId: sessionResponse.data.user?.id,
              email: sessionResponse.data.user?.email,
            });

            // Set the session in Redux
            const { data } = await supabase.auth.getSession();
            dispatch(setSession(data.session));
            ToastService.success(`Facebook login successful!`);

            // Delay navigation to ensure toast is shown
            setTimeout(() => navigate('/'), 500);
          } catch (error) {
            ToastService.success(`An unexpected error occurred. Please try again.`);
            setTimeout(() => navigate('/auth'), 500);
          }
        })();
      } else {
        // Handle missing tokens
        ToastService.success(`Missing tokens in the callback URL.`);
        setTimeout(() => navigate('/auth'), 500);
      }
    }
  }, [navigate, dispatch]);

  return null;
};

export default AuthRedirect;
