





import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Button, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { startTest } from "../../store/thunks/testThunks";
import {
  decrementCurrentQuestionIndex,
  incrementCurrentQuestionIndex,
  selectCurrentSectionModule,
  selectIsEnglishModule,
  setCurrentQuestionIndex,
} from "../../store/slices/testSlice";
import SATTestPopup from "../SATTestPopup";
import QuestionPanel from "./QuestionPanel";
import HighlightFeature from "./HighlightFeature";
import DesmosCalculator from "./DesmosCalculator";
import Timer from "./Timer";
import ReviewScreen from "../SATTest/ReviewScreen";
import { RootState, useAppDispatch } from "../../store";
import ReferenceSheet from "./ReferenceSheet";
import { Constants } from "../../constants";
import { PreviewEndPricingPlans } from "./PreviewEndPricingPlans";
import { Test } from "../../interfaces/Tests/Test";
import messageService from "../../services/messageService";
import DirectionsPopup from "./DirectionsPage/DirectionsPopup";
import {
  BorderColor as Highlighter,
  MoreVert,
  PunchClockSharp as Clock,
  Calculate as Calculator,
  Functions as Functions,
  HelpOutline,
  ViewHeadline,
  CheckBox,
  SpeakerNotes,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ToastService from "../../services/toastService";
import LineReader from "./LineReader";
import Notes from "./Notes";
import { socketEvents } from "../../constants/socketEvents";

const SATTestLayout: React.FC<{ onModuleEnd: () => void, isPreview: boolean }> = ({ onModuleEnd, isPreview }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentSectionModule = useSelector(selectCurrentSectionModule);
  const isEnglishModule = useSelector(selectIsEnglishModule);
  const Wrapper = isEnglishModule ? HighlightFeature : React.Fragment;
  const activeTest = useSelector((state: any) => state.tests.activeTest as Test);
  const { displayName } = useSelector(
    (state: RootState) => state.user
  );
  const currentQuestionIndex = useSelector(
    (state: any) => state.tests.currentQuestionIndex || 0
  );
  const questions = useSelector(
    (state: any) => state.tests.activeTest?.modules?.[0]?.questions || []
  );
  const currentQuestion = questions[currentQuestionIndex]; // Always get the latest current question

  const [open, setOpen] = useState(false);
  const [showCalculator, setShowCalculator] = useState(false);
  const [showReferenceSheet, setShowReferenceSheet] = useState(false);
  const [showPricingPlans, setShowPricingPlans] = useState(false);
  const [showTimer, setShowTimer] = useState(true);
  const [timeLeft, setTimeLeft] = useState(activeTest.timeRemaining ?? 60 * 30); // Example timer value
  const hasModuleEnded = useRef(false); // Ref to track module end
  const [showReviewScreen, setShowReviewScreen] = useState(false);
  // const logCounter = useRef(0); // Keeps track of the 30-second intervals
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showLineReader, setShowLineReader] = useState(false);
  const openMoreMenu = Boolean(anchorEl);
  const [isNotesCollapsed, setIsNotesCollapsed] = useState(false);
  const moduleName = useSelector((state: any) => state.tests.activeTest.currentModuleName);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const logCounter = useRef(0);

  const toggleNotes = () => {
    setIsNotesCollapsed(!isNotesCollapsed);
  }

  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setAnchorEl(null);
  };


  const handleOpenPopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const startTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current); // Clear any existing interval
    }
  
    intervalRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          if (!hasModuleEnded.current) {
            hasModuleEnded.current = true; // Mark module as ended
            onModuleEnd();
          }
          clearInterval(intervalRef.current!);
          return 0;
        }
  
        logCounter.current += 1;
        if (logCounter.current % 4 === 0) {
          messageService.sendMessage({
            testId: activeTest?.testId,
            eventType: socketEvents.QuestionStateUpdate,
            data: {
              moduleName,
              questionId: currentQuestion?.questionId,
              testUpdates: { timeSpent: 0 },
              questionUpdates: { timeSpent: 0 },
            },
          });
        }
  
        return prevTime - 1;
      });
    }, 1000);
  };
  

  const stopTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    logCounter.current = 0; // Reset the log counter
  };
  

  useEffect(() => {
    startTimer();
  
    return () => {
      stopTimer(); // Cleanup when the component unmounts
    };
  }, [moduleName]);

  useEffect(() => {
    if (!activeTest) {
      dispatch(startTest({isPreview: false}));
    }
  }, [dispatch, activeTest]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNextQuestion = () => {
    if (isPreview && currentQuestionIndex == 6) {
      setShowPricingPlans(true);
      localStorage.setItem(Constants.Storage.IS_PREVIEW_COMPLETED, 'y');
      return;
    }

    if (showReviewScreen) {
      hasModuleEnded.current = true; // Mark module as ended
      onModuleEnd(); // Trigger module completion
      return;
    }

    if (currentQuestionIndex === questions.length - 1) {
      setShowReviewScreen(true);
      return;
    }
    // stopQuestionTimer();
    dispatch(incrementCurrentQuestionIndex());
  };

  const handlePreviousQuestion = () => {
    dispatch(decrementCurrentQuestionIndex());
  };

  const navigateToQuestion = (index: number) => {
    setShowReviewScreen(false);
    dispatch(setCurrentQuestionIndex(index));
    handleClose();
  };

  const toggleLineReader = () => {
    handleCloseMoreMenu();
    setShowLineReader(!showLineReader);
  }

  const toggleCalculator = () => setShowCalculator((prev) => !prev);
  const toggleReferenceSheet = () => setShowReferenceSheet((prev) => !prev);

  const handleReviewSubmit = () => {
    setShowReviewScreen(false); // Close review screen
  };

  const goBackToTestListing = () => {
    handleClose();
    ToastService.success('Test Saved Successfully!');
    navigate("/");
  }

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#E1ECF7",
          px: 2,
          borderBottom: "2px solid #C2CFE0",
        }}
      >
        {/* First Row */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          {/* Left Section */}
          <Box sx={{ display: "flex", flexDirection:"column", }}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                color: "#000",
                fontSize: "16px",
              }}
            >
              {currentSectionModule}
            </Typography>
            <Button
              variant="text"
              size="small"
              onClick={handleOpenPopup}
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                color: "#0D47A1",
              }}
            >
              Directions ▼
            </Button>
          </Box>

          {/* Center Section */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {showTimer && <Timer timeLeft={timeLeft} />}
            {!showTimer && <Clock />}
            <Button
              size="small"
              sx={{
                textTransform: "none",
                color: "#0D47A1",
                fontWeight: "bold",
                mt: 1,
              }}
              onClick={() => setShowTimer((prev) => !prev)}
            >
              {showTimer ? "Hide" : "Show"}
            </Button>
          </Box>

          {/* Right Section */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {!isEnglishModule && (
        <>
          {/* Calculator Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
              border: showCalculator ? "2px solid #0D47A1" : "none", // Highlight when active
              borderRadius: "4px",
              padding: showCalculator ? "4px" : "0", // Add some padding when active
              backgroundColor: showCalculator ? "#E3F2FD" : "transparent", // Subtle background change
            }}
            onClick={toggleCalculator}
          >
            <Button
              size="small"
              variant="text"
              sx={{
                textTransform: "none",
                color: "#0D47A1",
                fontWeight: "bold",
              }}
            >
              <Calculator />
            </Button>
            <Typography
              variant="body2"
              sx={{
                color: "#0D47A1",
                fontWeight: "bold",
                mt: 0.5,
              }}
            >
              {"Calculator"}
            </Typography>
          </Box>

          {/* Reference Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
              border: showReferenceSheet ? "2px solid #0D47A1" : "none", // Highlight when active
              borderRadius: "4px",
              padding: showReferenceSheet ? "4px" : "0", // Add some padding when active
              backgroundColor: showReferenceSheet ? "#E3F2FD" : "transparent", // Subtle background change
            }}
            onClick={toggleReferenceSheet}
          >
            <Button
              size="small"
              variant="text"
              sx={{
                textTransform: "none",
                color: "#0D47A1",
                fontWeight: "bold",
              }}
            >
              <Functions />
            </Button>
            <Typography
              variant="body2"
              sx={{
                color: "#0D47A1",
                fontWeight: "bold",
                mt: 0.5,
              }}
            >
              {"Reference"}
            </Typography>
          </Box>
        </>
      )}

            {isEnglishModule && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                  border: !isNotesCollapsed ? "2px solid #0D47A1" : "none", // Highlight when active
                  borderRadius: "4px",
                  padding: !isNotesCollapsed ? "4px" : "0", // Add some padding when active
                  backgroundColor: !isNotesCollapsed ? "#E3F2FD" : "transparent", // Subtle background change
                }}
                onClick={toggleNotes}
              >
                <Button
                  size="small"
                  variant="text"
                  sx={{
                    textTransform: "none",
                    color: "#0D47A1",
                    fontWeight: "bold",
                  }}
                >
                  <Highlighter sx={{ fontSize: "18px", color: "#0D47A1", mr: 2 }} />
                  <SpeakerNotes sx={{ fontSize: "18px", color: "#0D47A1" }} />
                </Button>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#0D47A1",
                    fontWeight: "bold",
                    mt: 0.5,
                  }}
                >
                  Highlights & Notes
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                size="small"
                variant="text"
                sx={{
                  textTransform: "none",
                  color: "#0D47A1",
                  fontWeight: "bold",
                }}
                onClick={handleMoreClick}
              >
                <MoreVert sx={{ fontSize: "18px", color: "#0D47A1" }} />
              </Button>
              <Typography
                variant="body2"
                sx={{
                  color: "#0D47A1",
                  fontWeight: "bold",
                  mt: 1,
                  cursor: 'pointer'
                }}
                onClick={handleMoreClick}
              >
                More
              </Typography>
              <Menu
                anchorEl={anchorEl}
                open={openMoreMenu}
                onClose={handleCloseMoreMenu}
                
              >
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <HelpOutline fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Help" />
                </MenuItem>
                {/* <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Keyboard fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Shortcuts" />
                </MenuItem> */}
                {/* <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Accessible fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Assistive Technology" />
                </MenuItem> */}
                <MenuItem onClick={toggleLineReader}>
                  <ListItemIcon>
                    <ViewHeadline fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Line Reader" />
                </MenuItem>
                <MenuItem onClick={goBackToTestListing}>
                  <ListItemIcon>
                    <CheckBox fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Save and Exit" />
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>


      {!showReviewScreen && (
        <>
          {
            isPreview && 
            <Box
            sx={{
              height: "30px",
              backgroundColor: "#E1ECF7",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderBottom: "1px solid #C2CFE0",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontWeight: "bold",
                color: "#555",
                fontSize: "14px",
              }}
            >
              THIS IS A PREVIEW TEST
            </Typography>
          </Box>
          }

          <Box
            sx={{
              display: "flex",
              flex: 1,
              overflow: "hidden",
              backgroundColor: "#FFFFFF",
              justifyContent: currentQuestion?.details?.stimulus ? "flex-start" : "center",
            }}
          >
            {currentQuestion?.details?.stimulus && (
              <Box
                sx={{
                  flex: 1,
                  p: 3,
                  backgroundColor: "#FFFFFF",
                  borderRight: "1px solid #C2CFE0",
                }}
              >
                <Wrapper field="stimulus">
                  <Typography
                    component="div"
                    dangerouslySetInnerHTML={{
                      __html: currentQuestion?.details?.stimulus || "Loading...",
                    }}
                    sx={{
                      fontSize: "16px",
                      color: "#333",
                      lineHeight: "1.5",
                      "& img": {
                        maxWidth: "150%", // Scale image width to 150% of its original size
                        height: "auto", // Maintain the aspect ratio
                        display: "block", // Prevent inline image rendering issues
                        margin: "10px 50px", // Add spacing around images
                        transform: "scale(1.5)", // Increase size by 50%
                        transformOrigin: "center", // Scale the image from the center
                      },
                    }}
                  />
                </Wrapper>
              </Box>
            )}

            {
              isEnglishModule &&
              <Notes toggleNotes={toggleNotes} isNotesCollapsed={isNotesCollapsed} />
            }

            <Box
              sx={{
                flex: currentQuestion?.details?.stimulus ? 1 : "0 1 50%", // Maintain same width as right half when centered
                p: 3,
                backgroundColor: "#FFFFFF",
                ...(currentQuestion?.details?.stimulus
                  ? {}
                  : {
                      maxWidth: "750px", // Only apply maxWidth when no stimulus
                      margin: "0 auto", // Center the box horizontally
                    }),
              }}
            >
              {currentQuestion && (
                <QuestionPanel
                  isPreview={isPreview}
                  question={currentQuestion}
                  questionIndex={currentQuestionIndex}
                />
              )}
            </Box>
          </Box>

        </>
      )}
      {showReviewScreen && (
        <ReviewScreen
          questions={questions}
          currentQuestionIndex={currentQuestionIndex}
          onQuestionClick={navigateToQuestion}
          onSubmit={handleReviewSubmit}
        />
      )}
      
      <Box
        sx={{
          height: "50px",
          backgroundColor: "#E1ECF7",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 3,
          borderTop: "2px solid #C2CFE0",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: "#000",
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          {displayName}
        </Typography>

        <Box
          onClick={handleOpen}
          sx={{
            backgroundColor: "#000",
            color: "#fff",
            px: 2,
            py: 0.5,
            borderRadius: "5px",
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          Question {currentQuestionIndex + 1} of {questions.length} ▼
        </Box>

        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="outlined"
            sx={{
              textTransform: "none",
              color: "#0D47A1",
              borderColor: "#0D47A1",
              fontWeight: "bold",
              "&:hover": { borderColor: "#0D47A1", backgroundColor: "#E3F2FD" },
            }}
            onClick={handlePreviousQuestion}
            disabled={currentQuestionIndex === 0}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              backgroundColor: "#0D47A1",
              color: "#fff",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#1565C0" },
            }}
            onClick={handleNextQuestion}
            // disabled={currentQuestionIndex === questions.length - 1}
          >
            Next
          </Button>
        </Box>
      </Box>

      <SATTestPopup
        open={open}
        onClose={handleClose}
        questions={questions}
        currentQuestionIndex={currentQuestionIndex}
        onQuestionClick={navigateToQuestion}
        onGoToReviewPage={() => {
          setShowReviewScreen(true); // Show the review screen
          handleClose(); // Close the popup
        }}
      />

      {showCalculator && (
        <Box
          sx={{
            position: "fixed",
            top: "100px",
            right: "50px",
            zIndex: 1000,
          }}
        >
          <DesmosCalculator />
        </Box>
      )}

      { showReferenceSheet && <ReferenceSheet /> }

      {
        showPricingPlans &&
        <PreviewEndPricingPlans isOpen={true} tagLine={Constants.Messages.Free_Preview_Ended}/>
      }

      {
        isPopupVisible && 
        <DirectionsPopup isVisible={isPopupVisible} onClose={handleClosePopup} moduleType={currentSectionModule.includes("Math") ? "math" : "english"} />
      }

      {
        showLineReader &&
        <LineReader onClose={toggleLineReader} />
      }
    </Box>
  );
};

export default SATTestLayout;