// src/App.tsx
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { clearSession, setSession } from "./store/slices/authSlice";
import { RootState, useAppDispatch } from "./store";
import Layout from "./components/Layout/Layout";
import AuthScreen from "./components/Auth/AuthScreen";
import AuthRedirect from "./components/Auth/AuthRedirect";
import SATTestPage from "./pages/SATTestPage";
import { lightTheme, darkTheme } from "./styles/theme";
import { StripeCheckoutForm } from "./components/StripeCheckout/StripeCheckoutForm";
import { supabase } from "./clients/supabaseClient";
import Home from "./components/App/Home";
import { analytics } from "./services/firebaseSetup";
import { setUserProperties } from "firebase/analytics";
import PrivacyPolicy from "./components/App/PrivacyPolicy";
import Profile from "./components/App/UserProfile";
import { StripeCheckoutReturn } from "./components/StripeCheckout/StripeChekoutReturn";
import { ToastContainer } from "react-toastify";
import { fetchUser } from "./store/thunks/userthunk";
import { fetchTests } from "./store/thunks/testThunks";
import messageService from "./services/messageService";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const theme = useSelector((state: RootState) => state.user.theme); // Ensure `theme` exists in `user` slice
  const isLoggedIn = useSelector((state: RootState) => !!state.auth.user);
  
  React.useEffect(() => {
    if (analytics) {
      const domain = window.location.hostname;
      setUserProperties(analytics, { domain });
    }
  }, []);

  // All globally required data 
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchUser());
      dispatch(fetchTests());

      const initializeSocket = async () => {
        try {
          messageService.initialize();
        } catch (error) {
          console.error("Error initializing socket:", error);
        }
      };

      initializeSocket();

      return () => {
        messageService.terminate(); // Terminate the socket connection
      };
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    const { data } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN" || event === "INITIAL_SESSION") {
        dispatch(setSession(session));
      } else if (event === "SIGNED_OUT") {
        dispatch(clearSession());
      }
    });

    return () => {
      data.subscription.unsubscribe();
    };
  }, [dispatch]);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    dispatch({ type: "user/setTheme", payload: newTheme });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
        <Routes>
          {/* Full-Screen SAT Route */}
          <Route
            path="/sat"
            element={
              <Layout hideLayout={true}>
                <SATTestPage />
              </Layout>
            }
          />

          {/* Other Routes */}
          <Route
            path="*"
            element={
              <Layout>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/auth" element={<AuthScreen />} />
                  <Route path="/auth/callback" element={<AuthRedirect />} />
                  {/* <Route path="/dashboard" element={<Dashboard />} /> */}
                  {/* <Route path="/tests" element={<TestListing />} /> */}
                  <Route path="/checkout" element={<StripeCheckoutForm />} />
                  <Route path="/return" element={<StripeCheckoutReturn />} />
                  {/* <Route path="/ielts" element={<IELTSTestPage />} /> */}
                </Routes>
              </Layout>
            }
          />
        </Routes>
      </ThemeProvider>
    </>
    
  );
};

export default App;
