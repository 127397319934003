import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { Box, IconButton, Typography } from "@mui/material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import MinimizeIcon from "@mui/icons-material/Minimize";

const DesmosCalculator: React.FC = () => {
  const calculatorRef = useRef<HTMLDivElement>(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  // Load Desmos script
  useEffect(() => {
    const loadDesmosScript = () => {
      const script = document.createElement("script");
      script.src =
        "https://www.desmos.com/api/v1.9.3/calculator.js?apiKey=dcb31709b452b1cf9dc26972add0fda6";
      script.async = true;
      script.onload = () => setIsScriptLoaded(true);
      document.body.appendChild(script);
    };

    if (!window.Desmos) {
      loadDesmosScript();
    } else {
      setIsScriptLoaded(true);
    }
  }, []);

  // Initialize Desmos calculator
  useEffect(() => {
    if (isScriptLoaded && calculatorRef.current) {
      const calculator = new window.Desmos.GraphingCalculator(
        calculatorRef.current,
        {
          keypad: true,
          expressions: true,
        }
      );

      return () => calculator.destroy();
    }
  }, [isScriptLoaded]);

  // Toggle expansion
  const toggleExpand = () => setIsExpanded((prev) => !prev);

  if (!isVisible) return null;

  return (
    <Draggable>
      <Box
        sx={{
          position: "fixed",
          top: "10%",
          left: "10%",
          zIndex: 1000,
          width: isExpanded ? "90vw" : "400px",
          height: isExpanded ? "90vh" : "400px",
          backgroundColor: "#fff",
          border: "1px solid #ddd",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
          overflow: "hidden",
        }}
      >
        {/* Header Toolbar */}
        <Box
          sx={{
            backgroundColor: "#1A1A1A",
            color: "#FFFFFF",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "8px",
            cursor: "move",
          }}
        >
          {/* Left Section */}
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Calculator
          </Typography>

          {/* Right Section: Toolbar Buttons */}
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <IconButton
              onClick={toggleExpand}
              size="small"
              sx={{ color: "#FFFFFF" }}
            >
              {isExpanded ? <MinimizeIcon /> : <OpenInFullIcon />}
            </IconButton>

            {/* <IconButton
              onClick={closeCalculator}
              size="small"
              sx={{ color: "#FFFFFF" }}
            >
              <CloseIcon />
            </IconButton> */}
          </Box>
        </Box>

        {/* Desmos Calculator */}
        <Box
          ref={calculatorRef}
          sx={{
            flex: 1,
            backgroundColor: "#F5F5F5",
          }}
        />
      </Box>
    </Draggable>
  );
};

export default DesmosCalculator;
