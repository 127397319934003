import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Avatar,
  Button,
  TextField,
  Container,
  Paper,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { fetchUser, updateUser } from "../../store/thunks/userthunk";
import { userService } from "../../services/userService";

const Profile: React.FC = () => {
  const dispatch = useAppDispatch();

  // Get user data from the Redux store
  const { displayName, email, photo, dob, loading } = useSelector(
    (state: RootState) => state.user
  );

  // Local state for editable fields
  const [isUploading, setIsUploading] = useState(false);
  const [username, setUsername] = useState(displayName || "");
  const [userDob, setUserDob] = useState(dob || "");
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState(photo ?? "");

  // Fetch user data on component mount
  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  // Sync local state with Redux state
  useEffect(() => {
    if (displayName) setUsername(displayName);
    if (dob) setUserDob(dob);
    if (photo) setPreviewImage(photo);
  }, [displayName, dob]); // ,photo

  const handleProfilePictureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setProfilePicture(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleSave = async () => {
    setIsUploading(true);
    try {
      const userData = {
        displayName: username,
        dob: userDob,
        photo: profilePicture ? await uploadProfilePicture(profilePicture) : undefined,
      };
      dispatch(updateUser(userData));
    } catch(ex) {}

    setIsUploading(false);
  };

  const uploadProfilePicture = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const result = await userService.uploadProfilePicture(formData);
      return result.url;
      // Update user profile with new picture URL or display it
    } catch (error) {
      console.error('Failed to upload profile picture:', error);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f8f9fa",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={3}
          sx={{
            padding: "30px",
            borderRadius: "8px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            Profile Settings
          </Typography>

          {/* Profile Picture */}
          <Box textAlign="center" sx={{ marginBottom: "20px" }}>
            <Avatar
              alt="User Profile"
              src={previewImage}
              sx={{ width: 120, height: 120, margin: "0 auto" }}
            />
            <Button
              variant="outlined"
              component="label"
              sx={{ marginTop: "10px" }}
            >
              Upload Picture
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={handleProfilePictureChange}
              />
            </Button>
          </Box>

          {/* User Info */}
          <Box component="form" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              label="Name"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              label="Email"
              fullWidth
              value={email || ""}
              disabled
            />
            <TextField
              label="Date of Birth"
              type="date"
              fullWidth
              value={userDob}
              onChange={(e) => setUserDob(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>

          {/* Save Changes Button */}
          <Box textAlign="center" sx={{ marginTop: "20px" }}>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={loading || isUploading}
              sx={{
                backgroundColor: "#FFD700",
                color: "#000",
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#FFC107" },
              }}
            >
              {loading || isUploading ? "Saving..." : "Save Changes"}
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Profile;
