export const priceIds = {
  OneAndOnlyOne: "price_1QcvD2Ab41VA2yEC1YciBK6N",
  SatFocusedLearner: "price_1QcvFHAb41VA2yECAxxjWXB2",
  UltimateLearner: "price_1QcvFxAb41VA2yECae1tfGjv"
}

export const pricingPlans = [
  {
    title: "One and Only One",
    price: "$0.99",
    price_id: priceIds.OneAndOnlyOne,
    features: [
      { text: "Single Full Length Exam", isSpecial: false },
      { text: "Detailed Answer to each Question", isSpecial: false },
      { text: "Score Report", isSpecial: false },
      { text: "Pause and Resume Later", isSpecial: true },
    ],
    btnText: "Start Your First Test!",
    btnColor: "#4CAF50", // Green
    hoverColor: "#388E3C",
  },
  {
    title: "SAT Focused Learner",
    price: "$5.99",
    price_id: priceIds.SatFocusedLearner,
    features: [
      { text: "10 Full Length Exams", isSpecial: false },
      { text: "Detailed Answer to each Question", isSpecial: false },
      { text: "Score Report", isSpecial: false },
      { text: "Get Access for 1 month", isSpecial: true },
    ],
    btnText: "Get the Complete Prep!",
    btnColor: "#FF9800", // Orange
    hoverColor: "#E65100",
  },
  {
    title: "SAT Ultimate Learner",
    price: "$9.99",
    price_id: priceIds.UltimateLearner,
    features: [
      { text: "20 Full Length Exams", isSpecial: false },
      { text: "Detailed Answer to each Question", isSpecial: false },
      { text: "Score Report", isSpecial: false },
      { text: "Get Access for 3 months", isSpecial: true },
    ],
    btnText: "Master the SAT with 20 Tests!",
    btnColor: "#2196F3", // Blue
    hoverColor: "#1565C0",
  },
];