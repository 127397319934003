import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { Box, IconButton, Typography } from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import MinimizeIcon from '@mui/icons-material/Minimize';

const ReferenceSheet: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Toggle expansion
  const toggleExpand = () => setIsExpanded((prev) => !prev);

  return (
    <Draggable handle=".handle">
      <Box
        sx={{
          position: 'fixed',
          top: '7%',
          // left: '30%',
          right: '2%',
          zIndex: 1000,
          width: isExpanded ? '60vw' : '1000px',
          height: isExpanded ? '70vh' : '700px',
          backgroundColor: '#fff',
          border: '1px solid #ddd',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '6px',
          overflow: 'hidden',
        }}
      >
        {/* Header Toolbar */}
        <Box
          className="handle"
          sx={{
            backgroundColor: '#1A1A1A',
            color: '#FFFFFF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '8px',
            cursor: 'move',
          }}
        >
          {/* Left Section */}
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Reference Sheet
          </Typography>

          {/* Right Section: Toolbar Buttons */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <IconButton
              onClick={toggleExpand}
              size="small"
              sx={{ color: '#FFFFFF' }}
            >
              {isExpanded ? <MinimizeIcon /> : <OpenInFullIcon />}
            </IconButton>
          </Box>
        </Box>

        {/* Content */}
        <Box
          sx={{
            flex: 1,
            padding: '16px',
            overflowY: 'auto',
            backgroundColor: '#FFFFFF',
          }}
        >
          {/* Insert Formula Images */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
              gap: '16px',
              marginBottom: '16px',
            }}
          >
            {/* Placeholder for Images */}
            <img src="https://varmt.blob.core.windows.net/images/Screenshot%202024-12-31%20at%205.08.23%E2%80%AFPM.png" alt="Circle Formula" style={{ width: '100%', height: '100%' }} />
            {/* Add additional formula images here */}
          </Box>
        </Box>
      </Box>
    </Draggable>
  );
};

export default ReferenceSheet;
