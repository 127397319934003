import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqs = [
  {
    question: 'How is the SAT different from the ACT?',
    answer: 'Both the SAT and ACT are accepted by most colleges in the U.S., and no preference is given to either test. The SAT is administered digitally everywhere, while the ACT is still offered on paper in most places with limited availability of a digital test. The SAT is shorter (2 hours and 14 minutes) compared to the ACT (2 hours and 55 minutes). The SAT Math section includes a built-in Desmos graphing calculator, which the ACT does not have. Additionally, the SAT Reading and Writing section features short reading passages with only one question per passage, whereas the ACT includes longer passages with multiple questions. The SAT also offers free, world-class practice tools to all students through Bluebook and Official SAT Practice on Khan Academy®.',
  },
  {
    question: 'How do I register for the SAT?',
    answer: 'SAT registration deadlines typically fall approximately five weeks before each test date. To register by mail, fill out the registration form in the College Board\'s Bulletin for the SAT Program, which you can obtain from your school counselor or by contacting ETS at 609–771–7600. You can also register online at www.collegeboard.com.',
  },
  {
    question: 'Am I allowed to use a calculator during the SAT?',
    answer: 'Yes, you are allowed to use a calculator on certain sections of the SAT, but it is not required. The SAT Math section includes a built-in Desmos graphing calculator for your use.',
  },
  {
    question: 'When should O start preparing for the SAT?',
    answer: 'It is recommended that students begin practicing for the SAT at least 2–3 months before their test date. Utilizing Official SAT Practice on Khan Academy allows students to study anytime, anywhere, focusing on the specific skills they need to improve to succeed on the SAT.',
  },
  {
    question: 'How much time should I study on Official SAT Practice?',
    answer: 'Research has shown that 6–8 hours of study with Official SAT Practice is associated with a 90-point score increase from the PSAT/NMSQT to the SAT, or from SAT to SAT. Twenty hours of study is associated with a score gain of 115 points. More than 8 million students have signed up for Official SAT Practice to help build skills and confidence before taking the SAT.',
  },
  {
    question: 'Is score on a practice test a good indication of what they’ll get on the actual test?',
    answer: 'Yes, practice test scores can provide a good indication of potential performance on the actual SAT. Taking full-length practice tests helps students become familiar with the test format and timing, which can contribute to improved performance on test day.',
  },
  {
    question: 'What is the SAT?',
    answer: 'The SAT is a multiple-choice, computer-based test created and administered by the College Board. Its purpose is to measure a high school student\'s readiness for college and provide colleges with a common data point to compare all applicants.',
  },
  {
    question: 'What are the main components of the SAT?',
    answer: 'The SAT consists of two main sections: Evidence-Based Reading and Writing, and Math. Each section is scored on a scale of 200–800, and the total score ranges from 400–1600.',
  },
  {
    question: 'How long is the SAT?',
    answer: 'The SAT is 2 hours and 14 minutes long, excluding breaks.',
  },
  {
    question: 'How often is the SAT offered?',
    answer: 'The SAT is offered several times a year. Test dates and registration deadlines can be found on the College Board website.',
  },
  {
    question: 'Can I take the SAT more than once?',
    answer: 'Yes, you can take the SAT multiple times. Many students choose to take the test more than once to improve their scores.',
  },
  {
    question: 'How do I send my SAT scores to colleges?',
    answer: 'You can send your SAT scores to colleges through your College Board account. Four score reports are included with your registration, and additional reports can be sent for a fee.',
  },
  {
    question: 'What is the cost of the SAT?',
    answer: 'The cost of the SAT varies depending on whether you register for the SAT with or without the optional Essay (note: as of June 2021, the SAT Essay is discontinued). Fee waivers are available for eligible students.',
  },
  {
    question: 'Are there accommodations available for students with disabilities?',
    answer: 'Yes, the College Board provides accommodations for students with documented disabilities. You can request accommodations through your school or by contacting the College Board Services for Students with Disabilities.',
  },
  {
    question: 'What should I bring on test day?',
    answer: 'On test day, you should bring an acceptable photo ID, your admission ticket, two No. 2 pencils with erasers, an approved calculator, and a snack if desired. Electronic devices, including cell phones, are not permitted in the testing room.',
  },
  {
    question: 'How is the SAT scored?',
    answer: 'Each section of the SAT is scored on a scale of 200–800, and the total score ranges from 400–1600. There is no penalty for guessing, so it is to your advantage to answer every question.',
  },
  {
    question: 'When will I receive my SAT scores?',
    answer: 'SAT scores are typically available online about two to three weeks after the test date. You will receive an email notification when your scores are ready.',
  },
  {
    question: 'How can I prepare for the SAT?',
    answer: 'There are various ways to prepare for the SAT, including self-study with official practice materials, taking SAT preparation courses, and using online resources such as Official SAT Practice on Khan Academy.',
  },
  {
    question: 'What is Official SAT Practice on Khan Academy?',
    answer: 'Official SAT Practice on Khan Academy is a free, personalized practice resource provided by the College Board. It includes full-length practice tests, interactive problems, and detailed answer explanations, helping students focus on their areas of improvement.',
  },
  {
    question: 'Can I study for the SAT on my phone?',
    answer: 'Yes, Official SAT Practice on Khan Academy can be accessed on mobile devices, allowing students to study anytime, anywhere. The Bluebook™ app also offers practice test previews and resources.',
  },
  {
    question: 'What is the digital SAT?',
    answer: 'The digital SAT is a shorter, adaptive version of the SAT administered on a computer. It retains the core purpose of assessing students’ college readiness but has been optimized for a more user-friendly and secure test experience.',
  },
  {
    question: 'What sections are included in the digital SAT?',
    answer: 'The digital SAT consists of two main sections: Reading and Writing, and Math. Both sections are computer adaptive, meaning the questions adapt to a student’s performance in real-time.',
  },
  {
    question: 'How does adaptive testing work on the digital SAT?',
    answer: 'Adaptive testing on the digital SAT adjusts the difficulty of questions based on a student’s performance in the first module of a section. This allows for a more precise measurement of a student’s abilities.',
  },
  {
    question: 'Are breaks provided during the SAT?',
    answer: 'Yes, the SAT includes a 10-minute break after the Reading and Writing section, as well as a shorter 5-minute break between modules in the Math section.',
  },
  {
    question: 'How does the built-in calculator on the SAT work?',
    answer: 'The digital SAT includes a built-in Desmos graphing calculator for use during the Math section. Students can also bring their own approved calculators.',
  },
  {
    question: 'Can I take notes during the digital SAT?',
    answer: 'Yes, students will receive scratch paper and pencils to take notes and work out problems during the digital SAT.',
  },
  {
    question: 'What is the best way to approach SAT practice tests?',
    answer: 'The best way to approach SAT practice tests is to simulate test conditions by timing yourself and completing full-length practice tests in one sitting. Review your answers to understand your mistakes and focus on areas for improvement.',
  },
  {
    question: 'What resources are available for SAT preparation?',
    answer: 'Resources for SAT preparation include Official SAT Practice on Khan Academy, practice tests in the Bluebook app, study guides, and private tutoring options. Additionally, many schools provide SAT prep resources for their students.',
  },
  {
    question: 'What is the score range for the SAT?',
    answer: 'The SAT is scored on a scale of 400–1600. Each section (Reading and Writing, and Math) is scored on a scale of 200–800.',
  },
  {
    question: 'How long should I study for the SAT?',
    answer: 'The amount of time needed to study for the SAT varies for each student. Many students benefit from studying for 2–3 months, with consistent practice leading up to the test date. Personalized plans can be created using Official SAT Practice on Khan Academy.',
  },
  {
    question: 'What is the best time to take the SAT?',
    answer: 'Most students take the SAT in the spring of their junior year or the fall of their senior year. This allows time for additional attempts if desired and ensures scores are available for college applications.',
  },
  {
    question: 'Can I cancel my SAT scores?',
    answer: 'Yes, you can cancel your SAT scores if you feel they do not reflect your performance. This must be done no later than 11:59 p.m. Eastern Time on the Wednesday after the test date. Score cancellations are permanent and cannot be undone.',
  },
  {
    question: 'How do colleges use SAT scores?',
    answer: 'Colleges use SAT scores as one component of the admissions process. Scores may also be used for course placement, scholarship eligibility, and academic advising.',
  },
  {
    question: 'What happens if I miss my SAT test date?',
    answer: 'If you miss your SAT test date, you can register for a future test. Keep in mind that fees paid for the missed test are generally non-refundable, though fee waivers may apply to eligible students.',
  },
  {
    question: 'How can parents support their child’s SAT preparation?',
    answer: 'Parents can support their child by creating a study plan, providing a quiet study environment, encouraging the use of Official SAT Practice on Khan Academy, and celebrating progress along the way.',
  },
];

const FAQsSection: React.FC = () => {
return (
  <Box sx={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
    <Typography variant="h4" sx={{ mb: 4, textAlign: 'center', fontWeight: 'bold' }}>
      Frequently Asked Questions
    </Typography>
    {faqs.map((faq, index) => (
      <Accordion key={index} sx={{ mb: 2, borderRadius: '8px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ fontWeight: 'bold', fontSize: '18px' }}>
          {faq.question}
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" sx={{ fontSize: '16px', lineHeight: '1.6' }}>
            {faq.answer}
          </Typography>
        </AccordionDetails>
      </Accordion>
    ))}
  </Box>
);
};

export default FAQsSection;
