import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import FeedbackModal from "../SATTest/FeedbackModal";
import Confetti from "react-confetti";
import messageService from "../../services/messageService";
import { useSelector } from "react-redux";
import { Test } from "../../interfaces/Tests/Test";
import { socketEvents } from "../../constants/socketEvents";

const CompletionScreen: React.FC = () => {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(true);
  const [confettiVisible, setConfettiVisible] = useState(true);
  const activeTest = useSelector((state: any) => state.tests.activeTest as Test);

  useEffect(() => {
    // Stop confetti after 5 seconds
    setConfettiVisible(true);
    const confettiTimeout = setTimeout(() => setConfettiVisible(false), 5000);
    return () => clearTimeout(confettiTimeout); // Cleanup timeout on component unmount
  }, [isFeedbackOpen]);

  useEffect(() => {
    messageService.sendMessage({
      testId: activeTest?.testId,
      eventType: socketEvents.TestStatus,
      data: {
        testUpdates: {
          timeSpent: 0,
          status: "completed"
        }
      },
    });
  }, []);

  const handleViewScore = () => {
    // Redirect to the score page
    window.location.href = process.env.REACT_APP_UI_HOST as string;
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#FFFFFF",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Confetti */}
      {confettiVisible && <Confetti width={window.innerWidth} height={window.innerHeight} />}

      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          pointerEvents: "none",
          backgroundImage: `radial-gradient(circle, rgba(255,223,186,0.7) 1px, transparent 1px),
            radial-gradient(circle, rgba(255,255,186,0.7) 1px, transparent 1px),
            radial-gradient(circle, rgba(186,255,201,0.7) 1px, transparent 1px),
            radial-gradient(circle, rgba(186,255,255,0.7) 1px, transparent 1px)`,
          backgroundSize: "20px 20px",
          animation: "confetti 10s linear infinite",
        }}
      ></Box>

      {/* Title */}
      <Typography
        variant="h4"
        sx={{
          color: "#000",
          fontWeight: "bold",
          marginBottom: "2rem",
        }}
      >
        You're All Finished!
      </Typography>

      {/* Card Section */}
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "12px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
          padding: "2rem",
          textAlign: "center",
          maxWidth: "500px",
          width: "90%",
        }}
      >
        {/* Illustration */}
        <Box
          sx={{
            width: "80px",
            height: "80px",
            margin: "0 auto 1rem",
            backgroundColor: "#F5F5F5",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h4" sx={{ color: "#3F51B5" }}>
            😊
          </Typography>
        </Box>

        {/* Text */}
        <Typography
          variant="body1"
          sx={{
            color: "#000",
            fontWeight: "400",
            marginBottom: "0.5rem",
          }}
        >
          Congratulations on completing a SAT practice test.
        </Typography>
      </Box>

      {/* Button Section */}
      <Box sx={{ display: "flex", gap: "1rem", marginTop: "2rem" }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#FFD600",
            color: "#000",
            fontWeight: "bold",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#FFC107",
            },
          }}
          onClick={handleViewScore}
        >
          View Your Score
        </Button>
      </Box>

      {/* Feedback Modal */}
      <FeedbackModal
        open={isFeedbackOpen}
        onClose={() => setIsFeedbackOpen(false)}
      />
    </Box>
  );
};

export default CompletionScreen;
