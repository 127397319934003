import React, { useEffect, useState } from "react";
import { Box, Typography, Button, keyframes } from "@mui/material";
import { priceIds, pricingPlans } from "../../../constants/pricingPlans";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

// Keyframes for animations
const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  }
`;

const PopupPricingCard: React.FC<{
  testName: string;
  x: number;
  y: number;
  onClose: () => void;
}> = ({ testName, x, y, onClose }) => {
  const navigate = useNavigate();
  const unlockedTestsCount = useSelector(
    (state: RootState) => state.user.unlockedTestsCount || 0
  );
  const [discountedPrice, setDiscountedPrice] = useState(0);

  useEffect(() => {
    const price = (20 - unlockedTestsCount) * 0.4991;
    setDiscountedPrice(parseFloat(price.toFixed(2)));
  }, [unlockedTestsCount])

  const onUnlock = (priceId: string) => {
    const plan = pricingPlans.find(a => a.price_id == priceId);
    if (plan) {
      const items = [
        {
          name: `${plan.title} Plan`,
          price: parseFloat(plan.price.replace(/[^0-9.]/g, "") ?? "0.99"),
          quantity: 1,
          price_id: priceId,
        },
      ];
      navigate(`/checkout`, { state: { items } });
    }
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: y,
        left: x,
        width: "320px",
        background: "linear-gradient(135deg, #1e3a8a, #2563eb)",
        borderRadius: "16px",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
        color: "#ffffff",
        padding: "20px",
        zIndex: 10,
        fontFamily: "Arial, sans-serif",
        cursor: "default",
        textAlign: "center",
        overflow: "hidden",
      }}
      onMouseLeave={onClose}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          marginBottom: "16px",
          fontSize: "1.2rem",
          textAlign: "center",
        }}
      >
        {testName}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginBottom: "12px",
          fontSize: "1rem",
          fontStyle: "italic",
          color: "#f5f5f5",
        }}
      >
        Ace your SAT with all 20 tests! Unlock your full potential now.
      </Typography>
      <Button
        variant="contained"
        fullWidth
        sx={{
          borderColor: "#ffffff",
          color: "#ffffff",
          fontWeight: "bold",
          fontSize: "1rem",
          borderRadius: "8px",
          marginBottom: "16px",
          "&:hover": {
            backgroundColor: "rgba(255,255,255,0.2)",
          },
        }}
        onClick={() => onUnlock(priceIds.OneAndOnlyOne)}
      >
        Unlock One Test for $0.99
      </Button>
      <Typography
        variant="subtitle2"
        sx={{
          marginBottom: "8px",
          fontSize: "0.9rem",
          color: "#d1d5db",
        }}
      >
        OR
      </Typography>
      <Button
        variant="outlined"
        fullWidth
        sx={{
          backgroundColor: "#22c55e",
          // ,
          color: "#ffffff",
          fontWeight: "bold",
          fontSize: "1.1rem",
          borderRadius: "8px",
          animation: `${pulse} 1.5s infinite`,
          "&:hover": {
            backgroundColor: "#16a34a",
          },
        }}
        onClick={() => onUnlock(priceIds.UltimateLearner)}
      >
        Unlock {20 - unlockedTestsCount} Tests for {discountedPrice}
      </Button>
      <Typography
        variant="body2"
        sx={{
          marginTop: "16px",
          fontSize: "0.8rem",
          color: "#cbd5e1",
        }}
      >
        Unlock all tests and get access to step-by-step solutions, detailed
        reports, and more!
      </Typography>
    </Box>
  );
};

export default PopupPricingCard;
