import React from "react";
import {
  Box,
  Typography,
  Modal,
  Paper,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useSelector } from "react-redux";
import { selectCurrentSectionModule } from "../store/slices/testSlice";
import { Question } from "../interfaces/Tests/Question";

interface SATTestPopupProps {
  open: boolean;
  onClose: () => void;
  questions: Question[];
  currentQuestionIndex: number;
  onQuestionClick: (index: number) => void;
  onGoToReviewPage: () => void; // New prop for review page navigation
}

const SATTestPopup: React.FC<SATTestPopupProps> = ({
  open,
  onClose,
  questions,
  currentQuestionIndex,
  onQuestionClick,
  onGoToReviewPage,
}) => {
  const currentSectionModule = useSelector(selectCurrentSectionModule);
  
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="question-popup">
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          p: 3,
          boxShadow: 24,
          outline: "none",
          borderRadius: "10px",
        }}
      >
        {/* Heading */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight="bold">
            {currentSectionModule}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Labels */}
        <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
          <Box display="flex" alignItems="center">
            <LocationOnIcon sx={{ fontSize: "18px", color: "#0D47A1" }} />
            <Typography variant="body2" ml={0.5}>
              Current
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <CheckBoxOutlineBlankIcon
              sx={{ fontSize: "18px", color: "#9E9E9E" }}
            />
            <Typography variant="body2" ml={0.5}>
              Unanswered
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <BookmarkBorderIcon sx={{ fontSize: "18px", color: "#D32F2F" }} />
            <Typography variant="body2" ml={0.5}>
              For Review
            </Typography>
          </Box>
        </Box>

        <Box sx={{ borderBottom: "1px solid #E0E0E0", mb: 2 }} />

        {/* Question Grid */}
        <Box mt={2} display="grid" gridTemplateColumns="repeat(5, 1fr)" gap={1}>
          {questions.map((question: Question, index: number) => {
            const isMarked = question.markedForReview;
            const isCurrent = currentQuestionIndex === index;
            const isAnswered =
              question.userAnswer !== null &&
              question.userAnswer !== undefined;

            return (
              <Box
                key={question.questionId}
                onClick={() => onQuestionClick(index)}
                sx={{
                  textAlign: "center",
                  padding: "5px",
                  fontSize: "14px",
                  cursor: "pointer",
                  color: isCurrent ? "#0D47A1" : "#000",
                  backgroundColor: isAnswered
                    ? "#C8E6C9"
                    : isCurrent
                    ? "#FFF"
                    : isMarked
                    ? "#FFEBEE"
                    : "#F8F8F8",
                  border: isMarked
                    ? "2px solid #D32F2F"
                    : isAnswered
                    ? "2px solid #388E3C"
                    : "1px dashed #B0BEC5",
                  borderRadius: "3px",
                  fontWeight: isCurrent ? "bold" : "normal",
                  position: "relative",
                }}
              >
                {index + 1}
                {isCurrent && (
                  <LocationOnIcon
                    sx={{
                      fontSize: "18px",
                      color: "#0D47A1",
                      position: "absolute",
                      top: "2px",
                      left: "2px",
                    }}
                  />
                )}
                {isMarked && (
                  <BookmarkBorderIcon
                    sx={{
                      fontSize: "16px",
                      color: "#D32F2F",
                      position: "absolute",
                      top: "2px",
                      right: "2px",
                    }}
                  />
                )}
              </Box>
            );
          })}
        </Box>

        {/* Footer */}
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            variant="contained"
            size="small"
            onClick={onGoToReviewPage}
            sx={{
              textTransform: "none",
              backgroundColor: "#0D47A1",
              color: "#fff",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#1565C0",
              },
            }}
          >
            Go to Review Page
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default SATTestPopup;
