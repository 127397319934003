import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';

const ModuleEndScreen: React.FC = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: '#3F51B5', // Light blue
          fontWeight: '600',
          marginBottom: '1rem',
        }}
      >
        This Module Is Over
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: '#000000', // Black text
          marginBottom: '0.5rem',
        }}
      >
        All your work has been saved.
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: '#666666', // Grey text
          marginBottom: '2rem',
        }}
      >
        You’ll move on automatically in just a moment. <br />
        Do not refresh this page or quit the app.
      </Typography>
      <CircularProgress
        sx={{
          color: '#3F51B5', // Same light blue color as the heading
        }}
      />
    </Box>
  );
};

export default ModuleEndScreen;
