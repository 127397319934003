import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextareaAutosize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { ChevronLeft, ChevronRight, Delete } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { updateQuestionState } from "../../store/slices/testSlice";
import { useDebounce } from "../../hooks/debounce.hook";

type NotesProps = {
  isNotesCollapsed: boolean;
  toggleNotes: () => void;
};

const Notes: React.FC<NotesProps> = ({ isNotesCollapsed, toggleNotes }) => {
  const dispatch = useAppDispatch();
  const moduleName = useSelector((state: any) => state.tests.activeTest.currentModuleName);
  const questionId = useSelector((state: any) => state.tests.activeTest.currentQuestionId);
  const notes = useSelector((state: RootState) => {
    const question = state.tests.activeTest?.modules?.[0]?.questions?.[state.tests.currentQuestionIndex];
    return question?.notes || [];
  });

  const [localNotes, setLocalNotes] = useState(notes);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState<number | null>(null);

  // Sync localNotes with Redux notes
  useEffect(() => {
    if (JSON.stringify(localNotes) !== JSON.stringify(notes)) {
      toggleNotes();
      setLocalNotes(notes);
    }
  }, [notes]);

  // Debounced update function
  const debouncedUpdate = useDebounce((updatedNotes: any[]) => {
    dispatch(
      updateQuestionState({
        moduleName,
        questionId,
        updates: { notes: updatedNotes },
      })
    );
  }, 1000);

  // Handle content changes
  const handleContentChange = (id: number, content: string) => {
    const updatedNotes = localNotes.map((note) =>
      note.id === id ? { ...note, content } : note
    );
    setLocalNotes(updatedNotes); // Update local state
    debouncedUpdate(updatedNotes); // Debounced Redux update
  };

  // Handle note deletion
  const handleDeleteClick = (id: number) => {
    setNoteToDelete(id);
    setDeleteConfirmationOpen(true);
  };

  const confirmDelete = () => {
    if (noteToDelete !== null) {
      const updatedNotes = localNotes.filter((note) => note.id !== noteToDelete);
      setLocalNotes(updatedNotes); // Update local state
      dispatch(
        updateQuestionState({
          moduleName,
          questionId,
          updates: { notes: updatedNotes },
        })
      );
    }
    setDeleteConfirmationOpen(false);
    setNoteToDelete(null);
  };

  const cancelDelete = () => {
    setDeleteConfirmationOpen(false);
    setNoteToDelete(null);
  };

  const getLightenedColor = (color: string): string => {
    switch (color) {
      case "#BBDEFB":
        return "#E3F2FD"; // Light Blue
      case "#FFEB3B":
        return "#FFF9C4"; // Light Yellow
      case "#F8BBD0":
        return "#FCE4EC"; // Light Pink
      default:
        return color;
    }
  };

  return (
    <>
      {localNotes.length > 0 && (
        <Box sx={{ position: "relative", display: "flex", alignItems: "flex-start" }}>
          {/* Collapse/Expand Button */}
          <IconButton
            onClick={toggleNotes}
            sx={{
              position: "absolute",
              left: isNotesCollapsed ? "-15px" : "-25px",
              top: "80%",
              transform: "translateY(-50%)",
              zIndex: 10,
              backgroundColor: "#E1ECF7",
              border: "1px solid #C2CFE0",
              borderRadius: "20px",
              "&:hover": { backgroundColor: "#C2CFE0" },
            }}
          >
            {isNotesCollapsed ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>

          {isNotesCollapsed ? (
            <Box
              sx={{
                width: "20px",
                backgroundColor: "#eaeaea",
                borderLeft: "1px solid #C2CFE0",
                height: "100%",
              }}
            />
          ) : (
            <Box
              sx={{
                flex: 1,
                maxWidth: "300px",
                borderLeft: "1px solid #C2CFE0",
                backgroundColor: "#F9FAFB",
              }}
            >
              <Box sx={{ p: 2, overflowY: "auto", height: "100vh" }}>
                <Typography variant="h6" sx={{ fontWeight: "bold", color: "#000", mb: 2 }}>
                  Notes
                </Typography>
                {localNotes.map((note) => (
                  <Box
                    key={note.id}
                    sx={{
                      mb: 2,
                      border: "1px solid #C2CFE0",
                      borderRadius: "8px",
                      backgroundColor: getLightenedColor(note.color),
                      cursor: "pointer",
                    }}
                  >
                    {/* Title */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: `${note.color}`,
                        padding: "4px",
                        borderRadius: "4px 4px 0 0",
                      }}
                    >
                      <TextareaAutosize
                        value={note.title}
                        placeholder="Title"
                        style={{
                          width: "calc(100% - 32px)",
                          border: "none",
                          outline: "none",
                          fontWeight: "bold",
                          fontSize: "14px",
                          backgroundColor: "transparent",
                          resize: "none",
                          margin: "0 4px",
                        }}
                      />
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteClick(note.id);
                        }}
                        size="small"
                        sx={{ color: "#D32F2F", padding: 0 }}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                    {/* Content */}
                    <TextareaAutosize
                      value={note.content}
                      onChange={(e) => handleContentChange(note.id, e.target.value)}
                      placeholder="Type your notes here..."
                      style={{
                        width: "100%",
                        border: "none",
                        outline: "none",
                        resize: "none",
                        fontSize: "14px",
                        padding: "4px",
                      }}
                      minRows={2}
                    />
                  </Box>
                ))}

                {/* Delete Confirmation Dialog */}
                <Dialog open={deleteConfirmationOpen} onClose={cancelDelete}>
                  <DialogTitle>Delete This Note?</DialogTitle>
                  <DialogContent>
                    <Typography>Are you sure you want to delete this note?</Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={cancelDelete} variant="outlined">
                      No
                    </Button>
                    <Button onClick={confirmDelete} variant="contained" color="error">
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default Notes;
