import React, { useState } from "react";
import Draggable from "react-draggable";
import { Box, IconButton } from "@mui/material";
import { DragIndicator, Close, OpenInFull, ArrowDropDown } from "@mui/icons-material";

interface LineReaderProps {
  initialPosition?: number; // Initial vertical position
  minHeight?: number; // Minimum height for the white line reader
  maxHeight?: number; // Maximum height for the white line reader
  onClose?: () => void; // Callback for closing the reader
  onExpandCollapse?: () => void; // Callback for expand/collapse action
}

const LineReader: React.FC<LineReaderProps> = ({
  initialPosition = 150,
  minHeight = 120,
  maxHeight = 360,
  onClose,
}) => {
  const [readerHeight, setReaderHeight] = useState(minHeight); // Adjustable height for the white line reader
  const [isExpanded, setIsExpanded] = useState(true); // State to manage expand/collapse
  const [readerWidth, setReaderWidth] = useState("50%"); // Default full width

  const handleResize = () => {
    let newHeight = readerHeight + 100;
    if (newHeight > maxHeight) {
      newHeight = minHeight;
    }
    setReaderHeight(newHeight);
  };

  const toggleExpand = () => {
    if (isExpanded) {
      setReaderWidth("50%"); // Collapse to half-width
    } else {
      setReaderWidth("100%"); // Expand to full width
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <Draggable
      axis="both"
      defaultPosition={{ x: 0, y: initialPosition }}
      bounds="parent"
      handle=".drag-handle"
    >
      <Box
        sx={{
          position: "absolute",
          left: "50%", // Center horizontally
          transform: "translateX(-50%)", // Adjust for centering
          width: readerWidth, // Dynamically control width
          zIndex: 9999,
          backgroundColor: "transparent", // Make sure it doesn't block transparency
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.5)", // Add subtle shadow
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 10px",
            height: "40px",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 1)",
            cursor: "grab",
          }}
          className="drag-handle"
        >
          {/* Drag Handle */}
          <DragIndicator sx={{ visibility: "hidden" }} />
          <DragIndicator sx={{ color: "#fff", fontSize: "24px" }} />
          {/* Buttons */}
          <Box>
            <IconButton
              size="small"
              onClick={toggleExpand} // Expand/Collapse action
              sx={{ color: "#fff" }}
            >
              <OpenInFull />
            </IconButton>
            <IconButton
              size="small"
              onClick={onClose}
              sx={{ color: "#fff" }}
            >
              <Close />
            </IconButton>
          </Box>
        </Box>

        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: `${readerHeight}px`,
            backgroundColor: "rgba(0, 0, 0, 0.88)", // Semi-transparent black
            clipPath: "polygon(0 0, 100% 0, 100% 40%, 0 40%, 0 60%, 100% 60%, 100% 100%, 0 100%)", // Excludes center area
          }}
        >
          {/* Background Content */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: -1, // Ensure background content is visible behind
            }}
          />
        </Box>

        {/* Resizable Handle */}
        <Box
          sx={{
            display: "flex", // Align content inside the box
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            height: "25px", // Increase height to accommodate the icon
            cursor: "pointer", // Pointer cursor for click/drag behavior
            backgroundColor: "rgba(0, 0, 0, 0.9)", // Semi-transparent black
          }}
          onClick={handleResize} // Resizable action
        >
          <ArrowDropDown sx={{ color: "rgba(255, 255, 255, 0.9)" }} />
        </Box>

        {/* Footer Section */}
        <Box
          sx={{
            height: "40px",
            backgroundColor: "rgba(0, 0, 0, 1)", // Dark footer background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
          }}
        />
      </Box>
    </Draggable>
  );
};

export default LineReader;
