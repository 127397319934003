import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  Button,
} from "@mui/material";

const AboutSAT: React.FC = () => {
  const readingData = [
    { domain: "Craft and structure", questions: "13-15" },
    { domain: "Information and ideas", questions: "12-14" },
    { domain: "Standard English conventions", questions: "11-15" },
    { domain: "Expression of ideas", questions: "8-12" },
  ];

  const mathData = [
    { domain: "Algebra", questions: "13-15" },
    { domain: "Advanced math", questions: "13-15" },
    { domain: "Problem-solving and data analysis", questions: "5-7" },
    { domain: "Geometry and trigonometry", questions: "5-7" },
  ];

  return (
    <Box sx={{ py: 5, px: 3, backgroundColor: "#F9F9F9" }}>
      {/* Title */}
      <Typography
        variant="h3"
        sx={{
          fontWeight: "bold",
          mb: 5,
          textAlign: "center",
          color: "#1A1A1A",
        }}
      >
        Preparing for the SAT
      </Typography>
      {/* Preparing for SAT */}
      <Box sx={{ textAlign: "center", mt: 5 }}>
        <Typography variant="body1" sx={{ mb: 3, color: "#555" }}>
          To excel in the SAT, practice regularly and focus on understanding the test structure. Use high-quality practice
          materials to simulate the test environment and track your progress.
        </Typography>
      </Box>

      <Box sx={{ maxWidth: "800px", margin: "0 auto" }}>
        {/* Reading Test */}
        <Box sx={{ mb: 5, p: 3, backgroundColor: "#FFFFFF", borderRadius: "8px", boxShadow: "0 4px 10px rgba(0,0,0,0.1)" }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              mb: 3,
              color: "#1A1A1A",
            }}
          >
            What's on the SAT Reading Test?
          </Typography>
          <Typography variant="body1" sx={{ mb: 3, color: "#555" }}>
            The SAT Reading Test focuses on comprehension, grammar, and critical reading skills. Here's what to expect:
          </Typography>
          <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table>
              <TableBody>
                {readingData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.domain}</TableCell>
                    <TableCell align="right">{row.questions}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Math Test */}
        <Box sx={{ mb: 5, p: 3, backgroundColor: "#FFFFFF", borderRadius: "8px", boxShadow: "0 4px 10px rgba(0,0,0,0.1)" }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              mb: 3,
              color: "#1A1A1A",
            }}
          >
            What's on the SAT Math Test?
          </Typography>
          <Typography variant="body1" sx={{ mb: 3, color: "#555" }}>
            The SAT Math Test focuses on algebra, problem-solving, and data analysis. Here's what to expect:
          </Typography>
          <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table>
              <TableBody>
                {mathData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.domain}</TableCell>
                    <TableCell align="right">{row.questions}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutSAT;
