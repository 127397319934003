import React, { useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  ListItemIcon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import HomeIcon from "@mui/icons-material/Home";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { supabase } from "../../clients/supabaseClient";
import { fetchUser } from "../../store/thunks/userthunk";
import { Constants } from "../../constants";

const themeColor = "#FFD700";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch(); // Use the typed dispatch

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const isLoggedIn = useSelector((state: RootState) => !!state.auth.user);
  const userProfile = useSelector((state: RootState) => state.user);
  const { displayName, email, dob } = userProfile;
  const userProfileImage = useSelector(
    (state: RootState) => "/default-avatar.png" // state.user.photo
  );

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchUser());
    }
  }, [dispatch, isLoggedIn]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    // Dispatch the RESET_STORE action
    dispatch({ type: "RESET_STORE" });

    // Clear tokens or other persisted data
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
    setAnchorEl(null);
  };

  const toggleMobileMenu = () => {
    setMobileOpen(!mobileOpen);
  };

  const mobileMenu = (
    <Box
      sx={{
        width: 250,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#f8f9fa",
      }}
      role="presentation"
      onClick={toggleMobileMenu}
      onKeyDown={toggleMobileMenu}
    >
      <Box
        sx={{
          padding: "20px",
          textAlign: "center",
          backgroundColor: themeColor,
          color: "#000",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          MockTests
        </Typography>
        {isLoggedIn ? (
          <Typography variant="body2">Hi, {displayName || "User"}</Typography>
        ) : (
          <Typography variant="body2">Welcome, Guest</Typography>
        )}
      </Box>

      <List sx={{ flexGrow: 1, padding: "10px" }}>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/")}>
            <ListItemIcon>
              <HomeIcon sx={{ color: themeColor }} />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/privacy-policy")}>
            <ListItemIcon>
              <PrivacyTipIcon sx={{ color: themeColor }} />
            </ListItemIcon>
            <ListItemText primary="Privacy Policy" />
          </ListItemButton>
        </ListItem>
      </List>

      <Divider />

      <Box sx={{ padding: "10px 20px" }}>
        <List>
          {!isLoggedIn ? (
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate("/auth")}>
                <ListItemIcon>
                  <LoginIcon sx={{ color: themeColor }} />
                </ListItemIcon>
                <ListItemText primary="Login" />
              </ListItemButton>
            </ListItem>
          ) : (
            <>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate("/profile")}>
                  <ListItemIcon>
                    <PersonIcon sx={{ color: themeColor }} />
                  </ListItemIcon>
                  <ListItemText primary="Profile" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon sx={{ color: themeColor }} />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItemButton>
              </ListItem>
            </>
          )}
        </List>
      </Box>

      <Divider />
      <Box sx={{ textAlign: "center", padding: "10px 0" }}>
        <IconButton href="https://facebook.com" target="_blank" rel="noopener noreferrer">
          <FacebookIcon sx={{ color: themeColor }} />
        </IconButton>
        <IconButton href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <TwitterIcon sx={{ color: themeColor }} />
        </IconButton>
      </Box>
    </Box>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.95)",
          zIndex: 3,
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          {/* Logo and Title */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              gap: 1,
            }}
            onClick={() => navigate("/")}
          >
            <Avatar
              src="https://varmt.blob.core.windows.net/images/favicon.png"
              alt="Logo"
              sx={{
                width: 40,
                height: 40,
              }}
            />
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: themeColor,
              }}
            >
              MockTests
            </Typography>
          </Box>

          <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", gap: 2 }}>
            <Button sx={{ color: "#FFD700", fontWeight: "bold" }} onClick={() => navigate("/")}>
              Home
            </Button>
            <Button
              sx={{ color: "#FFD700", fontWeight: "bold" }}
              onClick={() => navigate("/privacy-policy")}
            >
              Privacy Policy
            </Button>
            {!isLoggedIn ? (
              <Button
                variant="outlined"
                sx={{
                  color: "#FFD700",
                  borderColor: "#FFD700",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#FFD700", color: "#000" },
                }}
                onClick={() => navigate("/auth")}
              >
                Login
              </Button>
            ) : (
              <>
                <IconButton onClick={handleMenuOpen}>
                  <Avatar alt="User Profile" src={userProfileImage} />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  sx={{
                    "& .MuiPaper-root": {
                      borderRadius: "8px",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.9)",
                      minWidth: "150px",
                      backgroundColor: "#fff",
                      padding: "10px 0",
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => navigate("/profile")}
                    sx={{
                      padding: "10px 20px",
                      display: "flex",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "#FFD700",
                        color: "#000",
                      },
                    }}
                  >
                    <PersonIcon sx={{ color: "#FFD700", marginRight: "10px" }} />
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={handleLogout}
                    sx={{
                      padding: "10px 20px",
                      display: "flex",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "#FFD700",
                        color: "#000",
                      },
                    }}
                  >
                    <LogoutIcon sx={{ color: "#FFD700", marginRight: "10px" }} />
                    Logout
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>

          <IconButton
            sx={{ display: { xs: "flex", md: "none" }, color: themeColor }}
            onClick={toggleMobileMenu}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={mobileOpen} onClose={toggleMobileMenu}>
        {mobileMenu}
      </Drawer>
    </>
  );
};

export default Header;
