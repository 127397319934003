import { io, Socket } from "socket.io-client";
import authService from "./authService";

interface AuthenticatedSocket extends Socket {
  auth: {
    token: string;
  };
}

class SocketService {
  private socket: AuthenticatedSocket | null = null;

  async connect() {
    const token = await authService.getValidToken();
    if (!token) {
      console.error("Cannot connect to socket server: No valid token");
      return;
    }

    if (!this.socket) {
      this.socket = io(process.env.REACT_APP_SOCKET_URL, {
        transports: ["websocket"],
        auth: { token },
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
      }) as AuthenticatedSocket;

      this.socket.on("connect", () => {
        console.log("Connected to socket server");
      });

      this.socket.on("disconnect", (reason) => {
        console.log("Disconnected from socket server:", reason);
      });

      this.socket.on("connect_error", (error) => {
        console.error("Socket connection error:", error.message);
      });

      this.socket.on("token-expired", async (data) => {
        console.warn("Token expired:", data.message);

        if (!this.socket) {
          return;
        }

        const newToken = await authService.refreshToken();
        if (!newToken) {
          console.error("Failed to refresh token. Disconnecting socket...");
          this.disconnect();
          return;
        }

        console.log("Reconnecting with new token...");
        this.socket.auth.token = newToken;
        this.socket.connect();
      });
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  sendMessage(event: string, message: any) {
    if (this.socket) {
      this.socket.emit(event, message);
    }
  }

  onMessage(event: string, callback: (...args: any[]) => void) {
    if (this.socket) {
      this.socket.on(event, callback);
    }
  }

  offMessage(event: string) {
    if (this.socket) {
      this.socket.off(event);
    }
  }
}

const socketService = new SocketService();
export default socketService;
