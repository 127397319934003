import { supabase } from "../clients/supabaseClient";
import store, { RootState } from "../store";
import { setSession, clearSession } from "../store/slices/authSlice";

class AuthService {
  async getValidToken(): Promise<string | null> {
    const state = store.getState() as RootState;
    const token = state.auth.accessToken;

    if (!token) {
      console.error("No token found in store");
      return null;
    }

    const isTokenExpired = this.isTokenExpired(token);
    if (isTokenExpired) {
      console.log("Token is expired. Refreshing...");
      const refreshedToken = await this.refreshToken();
      return refreshedToken;
    }

    return token;
  }

  getToken(): string | null {
    const state = store.getState() as RootState;
    const token = state.auth.accessToken;

    if (!token) {
      console.error("No token found in store");
      return null;
    }

    const isTokenExpired = this.isTokenExpired(token);
    if (isTokenExpired) {
      console.error("Token is expired. Please refresh it.");
      return null; // Return null to indicate the token needs refreshing
    }

    return token;
  }

isTokenExpired(token: string): boolean {
    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Math.floor(Date.now() / 1000);
      return payload.exp < currentTime;
    } catch (error) {
      console.error("Error decoding token:", error);
      return true; // Assume expired if decoding fails
    }
  }

  async refreshToken(): Promise<string | null> {
    try {
      const { data, error } = await supabase.auth.refreshSession();
      if (error || !data?.session?.access_token) {
        console.error("Failed to refresh token:", error);
        store.dispatch(clearSession());
        return null;
      }

      const newToken = data.session.access_token;
      store.dispatch(setSession(data.session));
      console.log("Token refreshed successfully");
      return newToken;
    } catch (err) {
      console.error("Error refreshing token:", err);
      store.dispatch(clearSession());
      return null;
    }
  }
}

const authService = new AuthService();
export default authService;
