import httpService from './httpService';
import { Test } from '../interfaces/Tests/Test';

export const testService = {
  getTest: async (testId: string): Promise<Test> => {
    const response = await httpService.get<Test>(`/tests/user-test?testId=${testId}`);
    return response;
  },
  getTests: async (): Promise<Test[]> => {
    const response = await httpService.get<Test[]>('/tests/all');
    return response;
  },
  startTest: async (isPreview: boolean, testId?: string): Promise<Test> => {
    const uri = isPreview ? '/tests/preview' : '/tests/start';
    const response = await httpService.post<Test>(uri, {isPreview, testId});
    return response;
  },
  getNextModule: async (testId: string, currentModuleName: string): Promise<Test> => {
    const response = await httpService.get<Test>(
      `/tests/next-module?testId=${testId}&currentModuleName=${currentModuleName}`
    );
    return response;
  },
};
