import React from "react";
import { Box, Typography } from "@mui/material";
import HighlightFeature from "./HighlightFeature";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { useDispatch, useSelector } from "react-redux";
import { selectIsEnglishModule, updateQuestionState } from "../../store/slices/testSlice";

interface QuestionOption {
  id: string;
  content: string; // This will be raw HTML
}

interface QuestionDetails {
  questionId: string;
  details: {
    stem: string; // Raw HTML for question stem
    answerOptions: QuestionOption[]; // Array of options with raw HTML content
  };
}

interface QuestionPanelProps {
  question: QuestionDetails; // Pass the current question data
  questionIndex: number; // Current question index
  isPreview: boolean;
}

const QuestionPanel: React.FC<QuestionPanelProps> = ({ question, questionIndex, isPreview }) => {
  const dispatch = useDispatch();
  const questionState = useSelector((state: any) => state.tests.activeTest.modules[0].questions[questionIndex]);
  const currentModuleName = useSelector((state: any) => state.tests.activeTest.currentModuleName);
  const [isABCActive, setIsABCActive] = React.useState(false); // Toggle state for showing strikethrough buttons
  const isEnglishModule = useSelector(selectIsEnglishModule);
  const Wrapper = isEnglishModule ? HighlightFeature : React.Fragment;

  const toggleMarkForReview = () => {
    if (!isPreview) {
      dispatch(
        updateQuestionState({
          moduleName: currentModuleName,
          questionId: question.questionId,
          updates: { markedForReview: !questionState.markedForReview },
        })
      );
    }
  };

  const toggleStrikeThrough = (optionIndex: number) => {
    const currentStrikes = questionState.strikeThrough || new Set();
    const updatedStrikes = new Set(currentStrikes);
    if (updatedStrikes.has(optionIndex)) {
      updatedStrikes.delete(optionIndex);
    } else {
      updatedStrikes.add(optionIndex);
    }

    if (!isPreview) {
      dispatch(
        updateQuestionState({
          moduleName: currentModuleName,
          questionId: question.questionId,
          updates: { strikeThrough: updatedStrikes },
        })
      );
    }
  };

  const markAsAnswer = (optionIndex: number) => {
    if (!isPreview) {
      // Map the index to corresponding letter (A, B, C, D...)
      const answerLetter = String.fromCharCode(65 + optionIndex); // Convert index to letter (A=65 in ASCII)
  
      dispatch(
        updateQuestionState({
          moduleName: currentModuleName,
          questionId: question.questionId,
          updates: { userAnswer: answerLetter }, // Send the letter instead of index
        })
      );
    }
  };  

  const { stem, answerOptions } = question.details;
  const strikeThroughs = questionState?.strikeThrough || new Set();
  const selectedAnswer = questionState?.userAnswer ? questionState?.userAnswer?.charCodeAt(0) - 65 : 10;

  return (
    <Box sx={{ 
      display: "flex", 
      flexDirection: "column", 
      gap: 2, 
      backgroundColor: "#FFFFFF",
      height: "85vh", // Take the full viewport height
      overflow: "auto", // Add scroll if content exceeds the height
    }}>
      {/* Question Bar */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#F7F7F7",
          px: 2,
          py: 1,
          borderBottom: "2px dashed black",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box
            sx={{
              backgroundColor: "#1A1A1A",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "18px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              height: "40px",
              borderRadius: "4px",
            }}
          >
            {questionIndex + 1}
          </Box>
          {/* Mark for Review */}
          <Box
            onClick={toggleMarkForReview}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              cursor: "pointer",
            }}
          >
            {questionState.markedForReview ? (
              <BookmarkIcon sx={{ color: "#FFD54F", fontSize: "20px" }} />
            ) : (
              <BookmarkBorderIcon sx={{ color: "#000", fontSize: "20px" }} />
            )}
            <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "16px", color: "#000" }}>
              Mark for Review
            </Typography>
          </Box>
        </Box>
        {/* Toggle ABC Button */}
        <Box
          onClick={() => setIsABCActive((prev) => !prev)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "40px",
            height: "40px",
            borderRadius: "6px",
            backgroundColor: isABCActive ? "#1A73E8" : "#DCE7F7",
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{
              color: isABCActive ? "#FFFFFF" : "#000",
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            ABC
          </Typography>
        </Box>
      </Box>

      {/* Question Stem */}
      <Wrapper field="stem">
        <Typography
          component="div"
          dangerouslySetInnerHTML={{ __html: stem }}
          sx={{ fontSize: "18px", fontWeight: "bold", color: "#000", mb: 2 }}
        />
      </Wrapper>

      {/* Options */}
<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
  {answerOptions.length > 0 ? (
    answerOptions.map((option, index) => (
      <Box
        key={option.id}
        onClick={() => markAsAnswer(index)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 2,
          py: 1,
          border: "2px solid",
          borderColor: selectedAnswer === index ? "#1A73E8" : "#DCE7F7",
          borderRadius: "6px",
          cursor: "pointer",
          textDecoration: strikeThroughs.has(index) ? "line-through" : "none",
        }}
      >
        <Typography
          component="div"
          dangerouslySetInnerHTML={{
            __html: `<strong>${String.fromCharCode(65 + index)}.</strong> ${option.content}`,
          }}
          sx={{
            fontSize: "16px",
            color: strikeThroughs.has(index) ? "#999" : "#333",
            fontWeight: strikeThroughs.has(index) ? "bold" : "400",
          }}
        />
        {/* Render Strikethrough Button Conditionally */}
        {isABCActive && (
          <Box
            onClick={(e) => {
              e.stopPropagation(); // Prevent marking as answer
              toggleStrikeThrough(index);
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "8px",
              width: "20px",
              height: "20px",
              border: "1px solid #999",
              borderRadius: "50%",
              color: "#999",
              fontWeight: "bold",
              fontSize: "12px",
              cursor: "pointer",
            }}
          >
            {strikeThroughs.has(index) ? "↻" : "—"}
          </Box>
        )}
      </Box>
    ))
  ) : (
    <Box sx={{ mt: 2 }}>
      <Typography variant="body1" sx={{ fontWeight: "bold", color: "#333", mb: 1 }}>
        Please enter your answer:
      </Typography>
      <input
        type="text"
        onChange={(e) => {
          const userInput = e.target.value;

          // Allow only valid characters
          const regex = /^[-0-9./]*$/;
          if (regex.test(userInput)) {
            if (!isPreview) {
              dispatch(
                updateQuestionState({
                  moduleName: currentModuleName,
                  questionId: question.questionId,
                  updates: { userAnswer: userInput },
                })
              );
            }
          }
        }}
        value={questionState.userAnswer || ""}
        style={{
          padding: "10px",
          border: "2px solid #DCE7F7",
          borderRadius: "6px",
          fontSize: "16px",
        }}
      />
    </Box>
  )}
</Box>
    </Box>
  );
};

export default QuestionPanel;
