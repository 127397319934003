import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useSelector } from "react-redux";
import { selectCurrentSectionModule } from "../../store/slices/testSlice";
import { Question } from "../../interfaces/Tests/Question";

interface ReviewScreenProps {
  questions: Question[]; // List of questions
  onQuestionClick: (index: number) => void; // Callback for navigating to a question
  currentQuestionIndex: number; // Current active question index
  onSubmit: () => void; // Callback when the user submits the review
}

const ReviewScreen: React.FC<ReviewScreenProps> = ({
  questions,
  onQuestionClick,
  currentQuestionIndex,
  onSubmit,
}) => {
  const currentSectionModule = useSelector(selectCurrentSectionModule);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        backgroundColor: "#F9FAFB",
        padding: 3,
      }}
    >
      {/* Title and Instructions */}
      <Typography variant="h5" fontWeight="bold" mb={2}>
        Check Your Work
      </Typography>
      <Typography
        variant="body1"
        mb={4}
        textAlign="center"
        sx={{ color: "#6B7280" }}
      >
        On test day, you won’t be able to move on to the next module until time
        expires.
        <br />
        For these practice questions, you can click{" "}
        <strong>Next</strong> when you’re ready to move on.
      </Typography>

      {/* Review Panel */}
      <Paper
        sx={{
          width: "90%",
          maxWidth: "800px",
          padding: 3,
          borderRadius: "12px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Section Title */}
        <Typography variant="h6" fontWeight="bold" mb={2}>
          {currentSectionModule}
        </Typography>

        {/* Labels */}
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Box display="flex" alignItems="center">
            <CheckBoxOutlineBlankIcon
              sx={{ fontSize: "18px", color: "#9E9E9E" }}
            />
            <Typography variant="body2" ml={1}>
              Unanswered
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <BookmarkBorderIcon sx={{ fontSize: "18px", color: "#D32F2F" }} />
            <Typography variant="body2" ml={1}>
              For Review
            </Typography>
          </Box>
        </Box>

        {/* Question Grid */}
        <Box
          display="grid"
          gridTemplateColumns="repeat(auto-fit, minmax(50px, 1fr))"
          gap={2}
        >
          {questions.map((question, index) => {
            const isMarked = question.markedForReview;
            const isCurrent = currentQuestionIndex === index;
            const isAnswered =
              question.userAnswer !== null &&
              question.userAnswer !== undefined;

            return (
              <Box
                key={question.questionId}
                onClick={() => onQuestionClick(index)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "10px",
                  fontSize: "14px",
                  cursor: "pointer",
                  color: isCurrent ? "#FFF" : "#0D47A1",
                  backgroundColor: isAnswered
                    ? "#C8E6C9" // Light green background for answered
                    : isCurrent
                    ? "#0D47A1"
                    : isMarked
                    ? "#FFEBEE"
                    : "#F8F8F8",
                  border: isMarked
                    ? "2px solid #D32F2F"
                    : isAnswered
                    ? "2px solid #388E3C" // Dark green border for answered
                    : "1px dashed #B0BEC5",
                  borderRadius: "6px",
                  fontWeight: isCurrent ? "bold" : "normal",
                  position: "relative",
                }}
              >
                {index + 1}
                {isCurrent && (
                  <LocationOnIcon
                    sx={{
                      fontSize: "18px",
                      color: "#FFF",
                      position: "absolute",
                      top: "5px",
                      left: "5px",
                    }}
                  />
                )}
              </Box>
            );
          })}
        </Box>
      </Paper>
    </Box>
  );
};

export default ReviewScreen;
