import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, IconButton, Button } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";
import { testService } from "../../../services/testService";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import PopupPricingCard from "./PopupPricingCard";

type TestState = "locked" | "not_started" | "in-progress" | "completed";

interface RenderedTest {
  id: number;
  name: string;
  state: TestState;
  testId?: string;
}

const TestOverview: React.FC = () => {
  const navigate = useNavigate();
  const unlockedTestsCount = useSelector(
    (state: RootState) => state.user.unlockedTestsCount || 0
  );
  const [testStates, setTestStates] = useState<RenderedTest[]>([]);
  const [loading, setLoading] = useState(true);

  // Popup state
  const [popupData, setPopupData] = useState<{
    show: boolean;
    x: number;
    y: number;
    placement: "left" | "right";
    test: RenderedTest | null;
  }>({ show: false, x: 0, y: 0, placement: "right", test: null });

  const [isHoveringPopup, setIsHoveringPopup] = useState(false);

  useEffect(() => {
    const fetchTests = async () => {
      try {
        const userTests = await testService.getTests();
        const totalTests = 20;
        const testSlots: RenderedTest[] = Array.from(
          { length: totalTests },
          (_, index) => ({
            id: index + 1,
            name: `Test ${index + 1}`,
            state: "locked",
          })
        );

        userTests.forEach((userTest, index) => {
          if (index < totalTests) {
            testSlots[index] = {
              ...testSlots[index],
              state: userTest.status as TestState,
              testId: userTest.testId,
            };
          }
        });

        for (let i = userTests.length; i < unlockedTestsCount; i++) {
          if (i < totalTests) {
            testSlots[i] = { ...testSlots[i], state: "not_started" };
          }
        }

        setTestStates(testSlots);
      } catch (error) {
        console.error("Failed to fetch tests:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTests();
  }, [unlockedTestsCount]);

  const handleHover = (test: RenderedTest, event: React.MouseEvent) => {
    if (test.state === "locked") {
      const rect = (event.target as HTMLElement).getBoundingClientRect();
      const screenWidth = window.innerWidth;
      const placement = rect.right + 320 > screenWidth ? "left" : "right"; // Check if there's space for the popup
      setPopupData({
        show: true,
        x: placement === "right" ? rect.right + 10 : rect.left - 310, // Adjust x based on placement
        y: rect.top + window.scrollY,
        placement,
        test,
      });
    }
  };

  const handleTestClick = (test: RenderedTest, event: React.MouseEvent) => {
    if (test.state === "locked") {
      handleHover(test, event);
    } else if (test.state == "in-progress") {
      // Navigate to the test page for fetched tests
      navigate(`/sat`, {
        state: { testId: test.testId },
      });
    } else if (test.state === "not_started") {
      // Navigate to generic SAT page for extra unlocked tests without data
      navigate(`/sat`);
    }
  };

  const closePopup = () => {
    if (!isHoveringPopup) {
      setPopupData({ show: false, x: 0, y: 0, placement: "right", test: null });
    }
  };

  const handleTestLeave = () => {
    setTimeout(() => {
      if (!isHoveringPopup) {
        closePopup();
      }
    }, 100); // Small delay to allow mouse to move to popup
  };

  const renderTests = () => (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "16px",
        justifyContent: "center",
      }}
    >
      {testStates.map((test) => (
        <Box
          key={test.id}
          onClick={(event) => handleTestClick(test, event)}
          onMouseEnter={(event) => handleHover(test, event)}
          // onMouseLeave={handleTestLeave}
          sx={{
            position: "relative",
            width: "150px",
            height: "150px",
            p: 2,
            border: "1px solid #E0E0E0",
            borderRadius: "8px",
            textAlign: "center",
            boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
            backgroundColor: test.state === "locked" ? "#F0F0F0" : "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            cursor: test.state === "locked" ? "pointer" : "default",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              mb: 1,
              fontSize: "0.9rem",
            }}
          >
            {test.name}
          </Typography>

          {test.state === "not_started" && (
            <IconButton
              sx={{
                backgroundColor: "#4CAF50",
                color: "#FFFFFF",
                "&:hover": { backgroundColor: "#45A049" },
              }}
            >
              <PlayCircleOutlineIcon sx={{ fontSize: 36 }} />
            </IconButton>
          )}
          {test.state === "in-progress" && (
            <IconButton
              onClick={() => navigate(`/sat`)}
              sx={{
                backgroundColor: "#FFC107",
                color: "#FFFFFF",
                "&:hover": { backgroundColor: "#E6B800" },
              }}
            >
              <PlayCircleOutlineIcon sx={{ fontSize: 36 }} />
            </IconButton>
          )}
          {test.state === "completed" && (
            <IconButton
              onClick={() => navigate(`/results/${test.id}`)}
              sx={{
                backgroundColor: "#2196F3",
                color: "#FFFFFF",
                "&:hover": { backgroundColor: "#1E88E5" },
              }}
            >
              <CheckCircleOutlineIcon sx={{ fontSize: 36 }} />
            </IconButton>
          )}
          {test.state === "locked" && (
            <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              cursor: "pointer",
              transition: "background-color 0.3s ease, transform 0.3s ease",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                transform: "scale(1.05)",
                "& .lock-icon": {
                  transform: "rotate(360deg)",
                },
              },
            }}
          >
            <LockIcon
              className="lock-icon"
              sx={{
                fontSize: 36,
                color: "#FFFFFF",
                transition: "transform 0.3s ease",
              }}
            />
          </Box>
          )}
        </Box>
      ))}
    </Box>
  );

  return (
    <Box sx={{ width: "100%", py: 5, px: 3, backgroundColor: "#F9F9F9" }}>
      <Typography
        variant="h4"
        sx={{ textAlign: "center", mb: 4, fontWeight: "bold" }}
      >
        Your Tests
      </Typography>

      {loading ? <Typography>Loading...</Typography> : renderTests()}

      {popupData.show && popupData.test && (
        <PopupPricingCard
          testName="Unlock More Tests!"
          x={popupData.x}
          y={popupData.y}
          onClose={closePopup}
        />
      )}
    </Box>
  );
};

export default TestOverview;
