import React from "react";
import { Backdrop, Box, Button, Fade, Modal, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Import close icon
import { pricingPlans } from "../../constants/pricingPlans";
import { useNavigate } from "react-router-dom";

export const PreviewEndPricingPlans: React.FC<{ isOpen: boolean; tagLine: string, onClose?: () => void }> = ({ isOpen, tagLine, onClose }) => {
  const navigate = useNavigate();

  const handleGetStarted = (planTitle: string) => {
    const plan = pricingPlans.find((p) => p.title === planTitle);
    if (plan) {
      const items = [
        {
          name: `${plan.title} Plan`,
          price: parseFloat(plan.price.replace(/[^0-9.]/g, "")),
          quantity: 1,
          price_id: plan.price_id,
        },
      ];
      navigate(`/checkout`, { state: { items } });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose || undefined} // Handle modal close only if onClose is provided
      closeAfterTransition
    >
      <Fade in={isOpen}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "800px", // Make it responsive with a max-width
            bgcolor: "background.paper",
            borderRadius: 4,
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            overflow: "auto",
            textAlign: "center",
          }}
        >
          {/* Conditional Close Button */}
          {onClose && (
            <IconButton
              onClick={onClose}
              sx={{
                position: "absolute",
                top: 16,
                right: 16,
                color: "grey.500",
              }}
            >
              <CloseIcon />
            </IconButton>
          )}

          <Typography
            variant="h4"
            sx={{
              mb: 3,
              fontWeight: "bold",
              color: "primary.main",
            }}
          >
            🎉 {tagLine}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3, color: "#555" }}>
            Ready to ace your SAT? Unlock full access to all our features and take your preparation to the next level:
          </Typography>
          <Box
            sx={{
              textAlign: "left",
              mb: 3,
              px: 2,
              py: 1,
              backgroundColor: "#F7FAFC",
              borderRadius: 2,
              border: "1px solid #E2E8F0",
            }}
          >
            <ul>
              <li>✔️ Full-length practice exams</li>
              <li>✔️ Detailed solutions for every question</li>
              <li>✔️ Personalized score reports</li>
              <li>✔️ Study at your own pace</li>
            </ul>
          </Box>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: "bold", color: "secondary.main" }}>
            Choose a plan and start preparing today!
          </Typography>

          {/* Pricing Plans Grid */}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "repeat(2, 1fr)", md: "repeat(3, auto)" },
              gap: 3,
              justifyContent: "center",
              mb: 3,
            }}
          >
            {pricingPlans.map((plan) => (
              <Box
                key={plan.title}
                sx={{
                  padding: 3,
                  backgroundColor: "#f7f7f7",
                  borderRadius: 2,
                  maxWidth: "300px",
                  width: "100%",
                  boxShadow: 2,
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.02)",
                    boxShadow: 4,
                  },
                  mx: "auto",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#1A237E", mb: 2, textAlign: "center" }}
                >
                  {plan.title}
                </Typography>
                <Typography variant="body1" sx={{ color: "#555", mb: 2, textAlign: "center" }}>
                  {plan.price}
                </Typography>
                <ul style={{ textAlign: "left", marginBottom: "16px" }}>
                  {plan.features.map((feature, index) => (
                    <li key={index}>
                      {feature.isSpecial ? (
                        <Typography sx={{ fontWeight: "bold", color: "primary.main" }}>
                          {feature.text}
                        </Typography>
                      ) : (
                        feature.text
                      )}
                    </li>
                  ))}
                </ul>
                <Button
                  variant="contained"
                  onClick={() => handleGetStarted(plan.title)}
                  sx={{
                    width: "100%",
                    backgroundColor: plan.btnColor,
                    color: "#fff",
                    textTransform: "none",
                    "&:hover": { backgroundColor: plan.hoverColor },
                  }}
                >
                  {plan.btnText}
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
