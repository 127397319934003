import React, { useState } from 'react';
import { Box, Button, Modal, Radio, RadioGroup, FormControlLabel, TextField, Typography } from '@mui/material';
import { reviewService } from '../../services/reviewService';

interface FeedbackModalProps {
  open: boolean;
  onClose: () => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ open, onClose }) => {
  const [rating, setRating] = useState<string>('');
  const [feedback, setFeedback] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (!rating) {
      alert('Please select a rating.');
      return;
    }

    setIsSubmitting(true);

    try {
      await reviewService.createReview({ rating: Number(rating), feedback });
      setRating('');
      setFeedback('');
      onClose(); // Close the modal
    } catch (error) {
      console.error('Failed to submit feedback:', error);
      alert('Something went wrong. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
          Overall, how would you rate your experience taking a test?
        </Typography>

        <RadioGroup
          value={rating}
          onChange={(e) => setRating(e.target.value)}
          sx={{ mb: 2 }}
        >
          <FormControlLabel value="5" control={<Radio />} label="Excellent" />
          <FormControlLabel value="4" control={<Radio />} label="Good" />
          <FormControlLabel value="3" control={<Radio />} label="Fair" />
          <FormControlLabel value="2" control={<Radio />} label="Poor" />
        </RadioGroup>

        <TextField
          fullWidth
          label="What went well? What can we improve?"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          multiline
          rows={4}
          variant="outlined"
          sx={{ mb: 2 }}
        />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button variant="text" color="primary" onClick={onClose}>
            Dismiss
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FeedbackModal;
