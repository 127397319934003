import React from "react";
import { Box, Typography, Button } from "@mui/material";

interface FinalCTAProps {
  onViewPricingClick: () => void;
}

const FinalCTA: React.FC<FinalCTAProps> = ({ onViewPricingClick }) => (
  <Box sx={{ py: 6, textAlign: "center", backgroundColor: "#1A73E8", color: "#fff" }}>
    <Typography variant="h4" sx={{ mb: 2, fontWeight: "bold" }}>
      Take the First Step Towards Your SAT Success!
    </Typography>
    <Typography variant="body1" sx={{ mb: 4 }}>
      Don't just prepare—excel. Start your SAT preparation journey today with MockTests.
    </Typography>
    <Button
      variant="contained"
      size="large"
      sx={{
        textTransform: "none",
        fontWeight: "bold",
        backgroundColor: "#FFD700",
        color: "#000",
        "&:hover": { backgroundColor: "#FFC300" },
      }}
      onClick={onViewPricingClick}
    >
      Start Practicing Now
    </Button>
  </Box>
);

export default FinalCTA;
