import React, { useEffect, useState, useRef } from 'react';
import InstructionsScreen from '../components/SATTest/Instructions';
import SATTestLayout from '../components/SATTest/SATTestLayout';
import ModuleEndScreen from '../components/SATTest/ModuleEnd';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../store';
import { startTest, getNextModule } from '../store/thunks/testThunks';
import BreakScreen from '../components/SATTest/BreakScreen';
import CompletionScreen from '../components/SATTest/CompletionScreen';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Constants } from '../constants';
import { PreviewEndPricingPlans } from '../components/SATTest/PreviewEndPricingPlans';
import { useLocation } from "react-router-dom";
import ToastService from '../services/toastService';


const SATTestPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(0); // 0: Instructions, 1: Test Layout, 2: Module End
  const [moduleEndTriggered, setModuleEndTriggered] = useState(false); // Track module end
  const [showPricingPlans, setShowPricingPlans] = useState(false);
  const { testId } = location.state || {};

  const dispatch = useAppDispatch();
  const activeTest = useSelector((state: RootState) => state.tests.activeTest);
  const loading = useSelector((state: RootState) => state.tests.loading);
  const error = useSelector((state: RootState) => state.tests.error);
  const isPreview = useSelector(
    (state: RootState) => !(state.user.unlockedTestsCount > 0)
  );

  // Ensure the module end API call is triggered only once
  const moduleEndTriggeredRef = useRef(false);

  useEffect(() => {
    const storedValue = localStorage.getItem(Constants.Storage.IS_PREVIEW_COMPLETED);
    // i need to handle loading state here so that rather showing pricing we show loader till we dont have dont the data
    if (storedValue && isPreview) {
      setShowPricingPlans(true); // Set the state with the retrieved value
    } else {
      setShowPricingPlans(false);
    }
  }, [isPreview]);

  useEffect(() => {
    if (moduleEndTriggered && activeTest) {
      setStep(1); // Move back to test layout after the API response updates activeTest
      setModuleEndTriggered(false);
      moduleEndTriggeredRef.current = false; // Reset for the next module
    }
  }, [activeTest, moduleEndTriggered]);

  const handleBack = () => {
    navigate('/'); // Additional back navigation logic can go here
    // Additional back navigation logic can go here
  };

  const handleNext = () => {
    if (showPricingPlans) {
      ToastService.info('Please purchase a payment plan to continue.');
      return;
    }
  
    if (step === 0) {
      dispatch(startTest({ isPreview, testId }))
        .unwrap()
        .then(() => {
          setStep(1); // Move to test layout
        })
        .catch((err) => {
          console.error('Failed to start test:', err);
        });
    }
  };  

  const handleModuleEnd = React.useCallback((skipBreak: boolean = false) => {
    // console.log('moduleEndTriggeredRef.current:', moduleEndTriggeredRef.current);
    if (!activeTest || moduleEndTriggeredRef.current) return;

    if (activeTest.currentModuleName === 'Math Module 2') {
      setStep(4); 
      return;
    }

    if (activeTest.currentModuleName === 'Module 2' && !skipBreak) {
      // Handle completion of the last module
      setStep(3); // Show the break screen while navigating to the Math Section
      return;
    }

    moduleEndTriggeredRef.current = true; // Prevent duplicate API calls
    setStep(2); // Show the module end screen

    dispatch(
      getNextModule({
        testId: activeTest.testId,
        currentModuleName: activeTest.currentModuleName,
      })
    )
      .unwrap()
      .then(() => {
        setModuleEndTriggered(true); // Signal transition to the next module
      })
      .catch((err) => {
        console.error('Failed to fetch next module:', err);
        setStep(0); // Optionally go back to instructions or handle errors
      });
  }, [activeTest, dispatch]);

  return (
    <>
      {step === 0 && (
        <InstructionsScreen
          isResumeTest={testId != undefined}
          onBack={handleBack}
          onNext={handleNext}
          loading={loading}
          error={error}
        />
      )}
      {step === 1 && <SATTestLayout isPreview={isPreview} onModuleEnd={handleModuleEnd} />}
      {step === 2 && <ModuleEndScreen />}
      {step === 3 && <BreakScreen onResume={() => handleModuleEnd(true)} />}
      {step === 4 && <CompletionScreen />}
      {
        showPricingPlans &&
        <PreviewEndPricingPlans isOpen={true} tagLine={Constants.Messages.Free_Preview_Ended}/>
      }
    </>
  );
};

export default SATTestPage;
