import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

interface HeroSectionProps {
  reducedHeight?: boolean; // New prop to control height
}

const HeroSection: React.FC<HeroSectionProps> = ({ reducedHeight = false }) => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state: RootState) => !!state.auth.user);
  const heroBg = "https://varmt.blob.core.windows.net/images/sat_hero_converter-min.jpg";

  return (
    <Box
      sx={{
        width: "100%",
        height: reducedHeight ? "60vh" : "100vh", // Adjust height dynamically
        backgroundImage: `url(${heroBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "#FFFFFF",
        textAlign: "center",
        position: "relative",
        overflow: "hidden",
        padding: "0 20px",
      }}
    >
      {/* Background Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          zIndex: 1,
        }}
      />

      {/* Hero Content */}
      <Box
        sx={{
          zIndex: 2,
          maxWidth: "1200px",
          width: "100%",
          textAlign: "left",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* Left Content */}
        <Box sx={{ flex: 1, padding: "20px" }}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "2rem", md: "2.5rem" },
              lineHeight: "1.2",
              mb: 2,
              color: "#FFD700",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
            }}
          >
            Experience the Real SAT
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mb: 4,
              fontSize: "1.1rem",
              lineHeight: "1.6",
              color: "#FFFFFF",
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.6)",
            }}
          >
            Prepare with MockTests and simulate the actual SAT environment.
            <br/>Practice in a digital setup that builds your confidence
            and boosts your scores.
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#FFD700",
              color: "#000",
              fontWeight: "bold",
              px: 4,
              py: 1.5,
              fontSize: "1rem",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
              "&:hover": { backgroundColor: "#FFC107" },
            }}
            onClick={() => navigate("/sat")}
          >
            Try a Free Preview
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default HeroSection;
