import socketService from "./socketService";

class MessageService {
  initialize() {
    socketService.connect();
  }

  terminate() {
    socketService.disconnect();
  }

  sendMessage(message: any) {
    socketService.sendMessage("message", message);
  }

  subscribeToMessages(callback: (message: string) => void) {
    socketService.onMessage("message", callback);
  }

  unsubscribeFromMessages() {
    socketService.offMessage("message");
  }
}

const messageService = new MessageService();
export default messageService;
