import { createAsyncThunk } from "@reduxjs/toolkit";
import { userService } from "../../services/userService";

// Define the structure of the user object
export interface User {
  displayName: string;
  email: string;
  dob: string;
  photo: string;
  unlockedTestsCount: number;
}

// Async thunk to fetch the current user
export const fetchUser = createAsyncThunk<User>(
  'user/fetchUser',
  async (_, { rejectWithValue }) => {
    try {
      const user = await userService.getUser();
      return user; // This now matches the `User` type
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch user data');
    }
  }
);

export const updateUser = createAsyncThunk<User, Partial<User>>(
  'user/updateUser',
  async (userData, { rejectWithValue }) => {
    try {
      const updatedUser = await userService.updateUser(userData);
      return updatedUser; // This now matches the `User` type
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to update user data');
    }
  }
);

