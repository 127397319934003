import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const BreakScreen: React.FC<{ onResume: () => void }> = ({ onResume }) => {
  const [timeLeft, setTimeLeft] = useState(60 * 10);
  const { displayName } = useSelector(
    (state: RootState) => state.user
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => Math.max(prev - 1, 0));
    }, 1000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, []);

  useEffect(() => {
    if (timeLeft == 0) {
      onResume();
    }
  }, [timeLeft])

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#000000",
        color: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: 4,
      }}
    >
      {/* Left Section: Timer */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 4,
        }}
      >
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            marginBottom: 2,
          }}
        >
          Remaining Break Time:
        </Typography>
        <Box
          sx={{
            fontSize: "3rem",
            fontWeight: "bold",
            color: "#FFEB3B",
            backgroundColor: "#121212",
            padding: "0.5rem 2rem",
            borderRadius: "8px",
            marginBottom: 2,
          }}
        >
          {formatTime(timeLeft)}
        </Box>
        <Button
          variant="contained"
          onClick={onResume}
          // disabled={timeLeft > 0}
          sx={{
            backgroundColor: "#FFEB3B",
            color: "#000000",
            fontWeight: "bold",
            textTransform: "none",
            padding: "0.5rem 2rem",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#FDD835",
            },
          }}
        >
          Resume Testing
        </Button>
      </Box>

      {/* Right Section: Instructions */}
      <Box
        sx={{
          maxWidth: "600px",
          textAlign: "left",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.25rem",
            fontWeight: "bold",
            marginBottom: 1,
          }}
        >
          Practice Test Break
        </Typography>
        <Typography
          sx={{
            fontSize: "1rem",
            color: "#B0B0B0",
            marginBottom: 2,
          }}
        >
          You can resume this practice test as soon as you're ready to move on. On test day, you’ll wait until the clock counts down. Read below to see how breaks work on test day.
        </Typography>
        <Typography
          sx={{
            fontSize: "1.25rem",
            fontWeight: "bold",
            marginBottom: 1,
          }}
        >
          Take a Break: Do Not Close Your Device
        </Typography>
        <Typography
          sx={{
            fontSize: "1rem",
            color: "#B0B0B0",
            marginBottom: 2,
          }}
        >
          After the break, a Resume Testing Now button will appear and you’ll start the next session.
        </Typography>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            marginBottom: 1,
          }}
        >
          Follow these rules during the break:
        </Typography>
        <ul style={{ color: "#B0B0B0", paddingLeft: "1rem" }}>
          <li>Do not disturb students who are still testing.</li>
          <li>Do not exit the app or close your laptop.</li>
          <li>Do not access phones, smartwatches, textbooks, notes, or the internet.</li>
          <li>Do not eat or drink near any testing device.</li>
          <li>Do not speak in the test room; outside the test room, do not discuss the exam with anyone.</li>
        </ul>
      </Box>

      {/* Footer */}
      <Box
        sx={{
          position: "absolute",
          bottom: "16px",
          color: "#B0B0B0",
          fontSize: "0.75rem",
        }}
      >
        {displayName}
      </Box>
    </Box>
  );
};

export default BreakScreen;
