import React from "react";
import { Box, Typography, Container } from "@mui/material";

const PrivacyPolicy: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#f8f9fa", // Light background for readability
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* Privacy Policy Content */}
      <Container maxWidth="md" sx={{ mt: 6, mb: 6 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4 }}>
          Privacy Policy
        </Typography>

        <Typography variant="body1" sx={{ mb: 4, lineHeight: "1.6" }}>
          Last Updated: <strong>Jan, 2025</strong>
        </Typography>

        {/* About Our SAT Mock Tests */}
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          1. About Our SAT Mock Tests
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, lineHeight: "1.6" }}>
          MockTests is designed to help students practice for the SAT in a
          simulated test environment. Here are some key points to clarify the
          nature of our services:
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, lineHeight: "1.6" }}>
          - <strong>Simulated Environment Only:</strong> Our platform provides
          mock tests designed to mimic the structure, timing, and environment of
          the SAT. These tests are intended for practice purposes only and have
          no connection to the actual SAT administered by the College Board.
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, lineHeight: "1.6" }}>
          - <strong>No Association with Actual SAT Questions:</strong> The
          questions provided in our mock tests are independently created and do
          not include or replicate any questions that will appear on the actual
          SAT exam. These are carefully crafted to help you understand the test
          format and prepare effectively.
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, lineHeight: "1.6" }}>
          - <strong>Scoring Disclaimer:</strong> While the scoring on our
          platform is designed to closely reflect SAT scoring principles, it is
          not an exact representation of the actual SAT scoring system. Your
          actual SAT score may differ based on multiple factors, including
          performance under official testing conditions. However, the scores
          from our platform are a strong indicator of your potential
          performance.
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, lineHeight: "1.6" }}>
          - <strong>Educational Purpose:</strong> MockTests is an independent
          tool created solely to assist students in their SAT preparation
          journey. We are not affiliated with the College Board or any other
          official testing organization.
        </Typography>

        {/* Information We Collect */}
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          2. Information We Collect
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, lineHeight: "1.6" }}>
          We only collect information necessary for the functionality of our
          platform, such as creating user accounts and tracking test
          performance. This includes:
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, lineHeight: "1.6" }}>
          - Name and email address for account creation.
          <br />
          - Performance data on mock tests for analysis and feedback.
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, lineHeight: "1.6" }}>
          We do not collect sensitive or unnecessary personal information.
        </Typography>

        {/* How We Use Your Information */}
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          3. How We Use Your Information
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, lineHeight: "1.6" }}>
          We use your information solely for the following purposes:
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, lineHeight: "1.6" }}>
          - To provide a seamless SAT preparation experience through our mock
          test platform.
          <br />
          - To track your progress and generate detailed feedback reports for
          your performance.
          <br />
          - To continuously improve the quality of our mock tests based on usage
          data and user feedback.
        </Typography>

        {/* Data Privacy and Security */}
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          4. Data Privacy and Security
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, lineHeight: "1.6" }}>
          We prioritize your privacy and security:
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, lineHeight: "1.6" }}>
          - <strong>Your Information Stays with You:</strong> All the data
          collected is used solely to improve your experience on the platform.
          We do not share, sell, or distribute your data to any third party
          under any circumstances.
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, lineHeight: "1.6" }}>
          - <strong>Secure Storage:</strong> All personal data and test
          performance records are stored securely using industry-standard
          encryption and security practices.
        </Typography>

        {/* New Section for Data Deletion Instructions */}
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          6. User Data Deletion
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, lineHeight: "1.6" }}>
          At MockTests, we respect your privacy and provide the option to delete your data at any time.
          If you wish to delete your account or any associated data, please follow the instructions below:
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, lineHeight: "1.6" }}>
          - Email us at <strong>support@mocktests.com</strong> with the subject line: <em>"Data Deletion Request"</em>.
          <br />
          - Include your account email or username in the email for verification.
          <br />
          - Once verified, your account and data will be permanently deleted within <strong>7 business days</strong>.
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, lineHeight: "1.6" }}>
          For any questions or concerns, feel free to contact us at <strong>support@mocktests.com</strong>.
        </Typography>

        {/* Contact Information Section */}
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          7. Contact Us
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, lineHeight: "1.6" }}>
          If you have any questions or concerns regarding this Privacy Policy, please contact us:
          <br />
          <strong>Email:</strong> support@mocktests.com
          <br />
        </Typography>

        {/* Contact Us */}
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          5. Contact Us
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, lineHeight: "1.6" }}>
          If you have any questions or concerns, contact us at:
          <br />
          <strong>Email:</strong> support@mocktests.com
          <br />
        </Typography>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
