import React, { useRef } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import HeroSection from "./SAT/HeroSection";
import WhyChooseMockTests from "./SAT/WhyChooseMockTests";
import AboutSAT from "./SAT/AboutSAT";
import PricingPlans from "./SAT/PricingPlans";
import FAQsSection from "./SAT/FAQsSection";
import FinalCTA from "./SAT/FinalCTA";
import RealTestExperience from "./SAT/RealTestExerperience";
import { RootState } from "../../store";
import TestOverview from "./SAT/TestsListing";

const Homepage: React.FC = () => {
  const pricingRef = useRef<HTMLDivElement>(null);
  const isTestsUnlocked = useSelector(
    (state: RootState) => state.user.unlockedTestsCount > 0
  );

  const scrollToPricing = () => {
    pricingRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box>
      {/* Conditionally render HeroSection or TestOverview */}
      {isTestsUnlocked ? (
        <TestOverview />
      ) : (
        <>
          <HeroSection reducedHeight />
          {/* Plans & Pricing Section: Showcase pricing options */}
          <div ref={pricingRef}>
            <PricingPlans />
          </div>
          <WhyChooseMockTests onViewPricingClick={scrollToPricing} />
        </>
      )}

      {/* Additional Sections */}
      <RealTestExperience />
      <AboutSAT />
      <FAQsSection />
      <FinalCTA onViewPricingClick={scrollToPricing} />
    </Box>
  );
};

export default Homepage;
