// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBw1gzJpftECRx8Oj2OZ9eforhL67RLNWo",
  authDomain: "mocktests-360.firebaseapp.com",
  projectId: "mocktests-360",
  storageBucket: "mocktests-360.firebasestorage.app",
  messagingSenderId: "13081598667",
  appId: "1:13081598667:web:28918f9e1ffb6448de594e",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics
const analytics = getAnalytics(app);

export { app, analytics };