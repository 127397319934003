import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { httpService } from "../../services/httpService";
import { StripeSessionResponse } from "../../interfaces/responses/StripeSessionResponse";
import { Box, Typography, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Confetti from "react-confetti";
import { useNavigate } from "react-router-dom";

export const StripeCheckoutReturn = () => {
  const [status, setStatus] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [confettiVisible, setConfettiVisible] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSessionStatus = async () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get("session_id");
  
      try {
        const data = await httpService.get<StripeSessionResponse>(`/stripe/session-status`, {
          session_id: sessionId,
        });
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
        setLoading(false);
  
        // Stop confetti after 5 seconds
        setTimeout(() => setConfettiVisible(false), 5000);
      } catch (error) {
        console.error("Error fetching session status:", error);
        setLoading(false);
      }
    };
  
    fetchSessionStatus(); // Call the async function
  }, []); // Empty dependency array ensures this only runs once
  

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "#f8f9fa",
        }}
      >
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (status === "open") {
    return <Navigate to="/checkout" />;
  }

  if (status === "complete") {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "100vh",
          backgroundColor: "#f8f9fa",
          padding: "20px",
        }}
      >
        {confettiVisible && <Confetti width={window.innerWidth} height={window.innerHeight} />}
        <CheckCircleIcon
          sx={{
            fontSize: 80,
            color: "#4caf50",
            marginBottom: "20px",
            animation: "pulse 2s infinite",
          }}
        />
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            color: "#333",
            marginBottom: "10px",
            animation: "fadeIn 1s ease-in-out",
          }}
        >
          Congratulations!
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "20px" }}>
          Your payment was successful. A confirmation email has been sent to{" "}
          <strong>{customerEmail}</strong>.
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: "40px" }}>
          You can now start practicing and boost your confidence for the exam!
          If you have any questions, please email{" "}
          <a href="mailto:info@mocktests.online">info@mocktests.online</a>.
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/")}
          sx={{
            backgroundColor: "#FFD700",
            color: "#000",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "#FFC107",
              transform: "scale(1.05)",
            },
            transition: "transform 0.2s ease-in-out",
          }}
        >
          Go to Dashboard
        </Button>
      </Box>
    );
  }

  return null;
};
