import React from "react";
import {
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Container,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { pricingPlans } from "../../../constants/pricingPlans";

const PricingPlans: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleGetStarted = (planTitle: string) => {
    const plan = pricingPlans.find((p) => p.title === planTitle);
    if (plan) {
      const items = [
        {
          name: `${plan.title} Plan`,
          price: parseFloat(plan.price.replace(/[^0-9.]/g, "")),
          quantity: 1,
          price_id: plan.price_id,
        },
      ];
      navigate(`/checkout`, { state: { items } });
    }
  };

  return (
    <Container maxWidth="lg" sx={{ my: 5 }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ fontWeight: "bold", mb: 5, color: theme.palette.primary.main }}
      >
        Pricing Plans
      </Typography>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr", md: "repeat(3, 1fr)" },
          gap: 4,
          justifyContent: "center",
        }}
      >
        {pricingPlans.map((plan) => (
          <Card
            key={plan.title}
            sx={{
              borderRadius: 4,
              boxShadow: 4,
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": {
                transform: "translateY(-8px)",
                boxShadow: 6,
              },
            }}
          >
            <CardContent sx={{ textAlign: "center", p: 4 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: theme.palette.primary.main,
                  mb: 2,
                }}
              >
                {plan.title}
              </Typography>
              <Typography variant="h6" sx={{ color: theme.palette.secondary.main, mb: 3 }}>
                {plan.price}
              </Typography>

              <Box component="ul" sx={{ textAlign: "left", pl: 2, mb: 3 }}>
                {plan.features.map((feature, index) => (
                  <li
                    key={index}
                    style={{
                      marginBottom: "8px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: feature.isSpecial ? "bold" : "normal",
                        color: feature.isSpecial ? theme.palette.primary.main : "inherit",
                      }}
                    >
                      {feature.text}
                    </Typography>
                  </li>
                ))}
              </Box>
            </CardContent>

            <CardActions sx={{ justifyContent: "center", mb: 2 }}>
              <Button
                variant="contained"
                onClick={() => handleGetStarted(plan.title)}
                sx={{
                  borderRadius: "20px",
                  backgroundColor: plan.btnColor,
                  color: "#fff",
                  textTransform: "none",
                  px: 4,
                  py: 1,
                  "&:hover": { backgroundColor: plan.hoverColor, transform: "scale(1.05)" },
                }}
              >
                {plan.btnText}
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>
    </Container>
  );
};

export default PricingPlans;
