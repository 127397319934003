// src/components/Auth/AuthScreen.tsx
import './Auth.css';
import { useState } from 'react';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../clients/supabaseClient';

const AuthScreen: React.FC = () => {
  const navigate = useNavigate();
  const [showSignIn, setShowSignIn] = useState(true);

  // Redirect to home on successful sign-in
  supabase.auth.onAuthStateChange((event) => {
    if (event === 'SIGNED_IN') {
      navigate('/');
    }
  });

  return (
    <div className="auth-container">
      <div className="auth-card">
        <div className="auth-header">
          <h2 className="auth-title">
            {showSignIn ? 'Welcome Back!' : "Let's Get Started"}
          </h2>
          <p className="auth-subtitle">
            {showSignIn
              ? 'Sign in to your account'
              : 'Create a new account'}
          </p>
        </div>
        <div className="auth-content">
          <Auth
            supabaseClient={supabase}
            appearance={{ theme: ThemeSupa }}
            providers={['google', 'facebook']}
            socialLayout="horizontal"
            view={showSignIn ? "sign_in" : "sign_up"}
            redirectTo={window.location.origin + "/auth/callback"}
          />
        </div>
      </div>
    </div>
  );
};

export default AuthScreen;
