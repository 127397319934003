import { User } from "../store/thunks/userthunk";
import httpService from "./httpService";

export const userService = {
  getUser: async (): Promise<User> => {
    const response = await httpService.get<User>('/users');
    return response;
  },
  updateUser: async (userData: Partial<User>): Promise<User> => {
    const response = await httpService.patch<User>('/users', userData);
    return response;
  },
  uploadProfilePicture: async (formData: FormData): Promise<{ url: string }> => {
    console.log('formData: ', formData)
    const response = await httpService.post<{ url: string }>('/users/upload-profile-picture', formData);
    return response;
  },
};
