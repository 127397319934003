import { createSlice } from "@reduxjs/toolkit";
import { fetchUser, updateUser } from "../thunks/userthunk";

interface UserState {
  theme: 'light' | 'dark'; // Add this line if not already present
  displayName: string | null;
  email: string | null;
  dob: string | null;
  photo: string | null;
  unlockedTestsCount: number | 0;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  theme: 'light', // Ensure this is initialized
  displayName: null,
  email: null,
  dob: null,
  photo: null,
  unlockedTestsCount: 0,
  loading: false,
  error: null,
};


const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch User
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        console.log("action.payload: ", action.payload);
        state.loading = false;
        state.displayName = action.payload.displayName;
        state.email = action.payload.email;
        state.dob = action.payload.dob;
        state.photo = action.payload.photo;
        state.unlockedTestsCount = action.payload.unlockedTestsCount;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

    // Update User
    builder
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.displayName = action.payload.displayName;
        state.dob = action.payload.dob;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default userSlice.reducer;
