import httpService from './httpService';

export const reviewService = {
  createReview: (reviewData: { rating: number; feedback?: string }) =>
    httpService.post('/reviews', reviewData),

  getReviews: () => httpService.get('/reviews'),

  getReviewById: (reviewId: string) => httpService.get(`/reviews/${reviewId}`),

  // updateReview: (reviewId: string, reviewData: { feedback?: string; isResolved?: boolean }) =>
  //   httpService.patch(`/reviews/${reviewId}`, reviewData),

  deleteReview: (reviewId: string) => httpService.delete(`/reviews/${reviewId}`),
};
