import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import userReducer from "./slices/userSlice";
import testReducer from "./slices/testSlice";
import { useDispatch } from "react-redux";

// Combine all reducers
const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  tests: testReducer,
});

// Create a root reducer with RESET_STORE handling
const rootReducer = (state: any, action: any) => {
  if (action.type === "RESET_STORE") {
    state = undefined; // Clear the entire state
  }
  return appReducer(state, action);
};

// Configure the store
const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;

// Export a typed version of the useDispatch hook
export const useAppDispatch = () => useDispatch<AppDispatch>();
