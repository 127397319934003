import React from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

const benefits = [
  {
    title: "Real Exam Practice",
    description: "Feel confident with authentic SAT-style tests.",
    buttonText: "Start Practicing",
    action: (scrollToPricing: () => void) => {
      scrollToPricing();
    },
  },
  {
    title: "Step-by-Step Solutions",
    description: "Learn from detailed explanations tailored for you.",
    buttonText: "See How It Works",
    action: () => {
      console.log("See How It Works clicked");
    },
  },
  {
    title: "Score Insights",
    description: "Identify your strengths and weaknesses to improve fast.",
    buttonText: "Get Your Report",
    action: () => {
      console.log("Get Your Report clicked");
    },
  },
  {
    title: "Affordable Plans",
    description: "Top-quality prep for every student at the best price.",
    buttonText: "View Pricing",
    action: (scrollToPricing: () => void) => {
      scrollToPricing();
    },
  },
];

interface WhyChooseMockTestsProps {
  onViewPricingClick: () => void;
}

const WhyChooseMockTests: React.FC<WhyChooseMockTestsProps> = ({ onViewPricingClick }) => (
  <Box sx={{ py: 5, px: 3, textAlign: "center" }}>
    <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4 }}>
      Why Choose MockTests for SAT?
    </Typography>
    <Grid container spacing={3}>
      {benefits.map((benefit, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Box
            sx={{
              p: 3,
              border: "1px solid #E0E0E0",
              borderRadius: "8px",
              boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
              backgroundColor: index % 2 === 0 ? "#FFFAF0" : "#F0F8FF", // Softer and more cohesive colors
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              "&:hover": {
                transform: "translateY(-8px)",
                boxShadow: "0px 6px 10px rgba(0,0,0,0.2)",
              },
            }}
          >
            <StarIcon sx={{ fontSize: "40px", color: "#FFD700", mb: 1 }} />
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1, color: "#1A1A1A" }}>
              {benefit.title}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, color: "#555" }}>
              {benefit.description}
            </Typography>
            <Button
              variant="outlined"
              onClick={() =>
                benefit.title === "Affordable Plans" ? benefit.action(onViewPricingClick) : benefit.action(onViewPricingClick)
              }
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                borderColor: "#1A73E8",
                color: "#1A73E8",
                "&:hover": {
                  backgroundColor: "#E3F2FD",
                  borderColor: "#1565C0",
                },
              }}
            >
              {benefit.buttonText}
            </Button>
          </Box>
        </Grid>
      ))}
    </Grid>
  </Box>
);

export default WhyChooseMockTests;
